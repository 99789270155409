import React from "react";
import "../App.css";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ShoppingCards from "./ShoppingCards/ShoppingCards";

import { NavLink } from "react-router-dom";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

const WeddingOrder = () => {
  return (
    <>
      <ScrollToTop />
      <Header />

      {/* Cards Section Starts Here */}
      <div className="container-fluid ">
        <div className="TitleText text-center">
          <h3 className="p-4">All</h3>
        </div>
        <div className="row ">
          <div className="col-sm-4 ">
            <div className="image-container">
              <img
                src="https://cdn.shopify.com/s/files/1/0466/5763/4466/collections/spring_1296x.jpg?v=1600258587"
                alt="DisplayImage"
                className="ImageCon1"
              />
              <div className="text-container1 text-center  mb-3">
                <button className="btn btn-secondary bg-white text-dark">
                  Explore
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-4 ">
            <div className="image-container">
              <img
                src="https://cdn.shopify.com/s/files/1/0466/5763/4466/collections/spring_a99a9c7d-2378-4f97-9527-8339f1ad953b_540x.jpg?v=1601804235"
                alt="DisplayImage"
                className="ImageCon1"
              />
              <div className="text-container1 text-center  mb-3">
                <button className="btn btn-secondary bg-white text-dark">
                  Explore
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-4 ">
            <div className="image-container">
              <img
                src="https://cdn.shopify.com/s/files/1/0466/5763/4466/collections/spring_43745e3e-6e22-44f6-a833-73df480faa8e_1296x.jpg?v=1600258132"
                alt="DisplayImage"
                className="ImageCon1"
              />
              <div className="text-container1 text-center  mb-3">
                <button className="btn btn-secondary bg-white text-dark">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row ">
          <div className="col-sm-4 ">
            <div className="image-container">
              <img
                src="https://cdn.shopify.com/s/files/1/0466/5763/4466/collections/spring_1296x.jpg?v=1600258587"
                alt="DisplayImage"
                className="ImageCon1"
              />
              <div className="text-container1 text-center  mb-3">
                <button className="btn btn-secondary bg-white text-dark">
                  Explore
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-4 ">
            <div className="image-container">
              <img
                src="https://cdn.shopify.com/s/files/1/0466/5763/4466/collections/spring_a99a9c7d-2378-4f97-9527-8339f1ad953b_540x.jpg?v=1601804235"
                alt="DisplayImage"
                className="ImageCon1"
              />
              <div className="text-container1 text-center  mb-3">
                <button className="btn btn-secondary bg-white text-dark">
                  Explore
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-4 ">
            <div className="image-container">
              <img
                src="https://cdn.shopify.com/s/files/1/0466/5763/4466/collections/spring_43745e3e-6e22-44f6-a833-73df480faa8e_1296x.jpg?v=1600258132"
                alt="DisplayImage"
                className="ImageCon1"
              />
              <div className="text-container1 text-center  mb-3">
                <button className="btn btn-secondary bg-white text-dark">
                  Explore
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cards Section Ends Here */}

      {/* Buying Cards Start */}

      <ShoppingCards />

      {/* Buying Cards End */}

      {/* Buying Cards Start */}

      <ShoppingCards />

      {/* Buying Cards End */}

      {/* Buying Cards Start */}

      <ShoppingCards />

      {/* Buying Cards End */}

      {/* Buying Cards Start */}

      <ShoppingCards />

      {/* Buying Cards End */}

      <Footer />
    </>
  );
};

export default WeddingOrder;
