import React from "react";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
function LoadingScreen() {
  return (
    <div className="loadingPage">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "100%",
        }}
      >
        <Loader type="TailSpin" color="#f743a6" height={100} width={100} />
      </div>
    </div>
  );
}

export default LoadingScreen;
