import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
const RefundPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <Header />

      <div className="container AboutSection p-5">
        <h6>Only exchange | No return | No Refund</h6>
        <p>
          We have a 15-day exchange policy, which means you have 15 days after
          receiving your item to request an exchange with the same product in
          case of any defect or a wrong product sent to you.
        </p>
        <h6>We do not accept any return or refund.</h6>
        <p>
          To be eligible for an exchange, your item must be in the same
          condition that you received it, unworn or unused, with tags, and in
          its original packaging. You’ll also need the receipt or proof of
          purchase.
        </p>

        <p>
          To start an exchange, you can contact us at{" "}
          <a href="mailto:kafipyar.customercare@gmail.com">
            kafipyar.customercare@gmail.com
          </a>{" "}
          Once your request is accepted, we’ll send you the shipping details as
          well as instructions on how and where to send your package. Items sent
          back to us without first requesting an exchange will not be accepted.
          <br />
          Products availed after exchange will be dispatched from our end only
          after receiving back the original product
        </p>
        <h6>Exchange Products</h6>
        <p>
          You can exchange the purchased product only with the same product
          <br />
          Exchange cannot be done with any other product of lesser, more or
          equivalent value
          <br />
          In the case of damaged / defective products or wrong products
          received, the exchange shipping cost from us to you will be borne by
          us.
        </p>

        <h6>Damages and issues</h6>

        <p>
          Please inspect your order upon reception and contact us immediately if
          the item is defective, damaged or if you receive the wrong item, so
          that we can evaluate the issue and make it right.
        </p>

        <h6>Exceptions / non-returnable items</h6>
        <p>
          Certain types of items cannot be returned, like perishable goods (such
          as food, flowers, or plants), custom products (such as special orders
          or personalized items), and personal care goods (such as beauty
          products). We also do not accept returns for hazardous materials,
          flammable liquids, or gases. Please get in touch if you have questions
          or concerns about your specific item.{" "}
        </p>

        <h6>Sale Products</h6>
        <p>
          Unfortunately, we cannot accept returns on sale items or gift cards
          unless there is a major defect.
        </p>
        <h6>Bulk Orders</h6>

        <p>
          All items are handmade and minor variants may occur. Bulk orders above
          50pcs are not eligible for exchange unless there is a major defect.
        </p>

        <h6>Exchanges</h6>

        <p>
          The fastest way to ensure you get what you want is to return the item
          you have, and once the return is accepted, make a separate purchase
          for the new item.
        </p>

        <h6>Shipping Details</h6>
        <p>
          To return your product, please ship it at the following address:
          <br />
          Kafipyar
          <br />
          276 Barkat Nagar, Tonk Phatak,
          <br />
          Jaipur, 302015
          <br />
          Ph: +919353155136
        </p>

        <h6>Shipping Cost</h6>

        <p>
          You will be responsible for paying for your own shipping costs for
          exchanging your item. Shipping costs are non-refundable.
          <br />
          In the case of damaged / defective products, the exchange shipping
          cost from us to you will be borne by us. You will be responsible for
          paying for your own shipping costs for exchanging your item.
          <br />
          Depending on where you live, the time it may take for your exchanged
          product to reach you, may vary.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default RefundPolicy;
