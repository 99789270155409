import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { productsAction } from "../../Redux/common/action";

function Account() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOutAdmin = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  const get_order = useSelector((state) =>
    state?.product?.get_order ? state?.product?.get_order : {}
  );

  const get_guery_by_status = useSelector((state) =>
    state?.product?.get_guery_by_status
      ? state?.product?.get_guery_by_status
      : []
  );

  console.log(get_guery_by_status);

  // Outputs: 'https:'
  console.log(window.location.protocol);

  // Outputs: 'timmousk.com'
  console.log(window.location.hostname);

  // Outputs: '/blog/react-get-current-url/'
  console.log(window.location.pathname);

  console.log(get_guery_by_status);

  useEffect(() => {
    dispatch(productsAction.getOrder());
    dispatch(productsAction.get_query_by_status("Requested"));

    return () => {};
  }, []);

  const orderByid = (e) => {
    // getOrderbyId
    const datas = new FormData();

    datas.append("order_id", "11");
    // datas.append("qty", 1);
    dispatch(productsAction.getOrderbyId(datas));

    return () => {};
  };

  const [first, setfirst] = useState("Requested");

  const productstatus = (e) => {
    setfirst(e);
    dispatch(productsAction.get_query_by_status(e));
  };

  return (
    <div>
      <Header />
      <div className="breadcrumbs_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_content">
                <h3>My account</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="main_content_area">
        <div className="container">
          <div className="account_dashboard">
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3">
                <div className="dashboard_tab_button">
                  <ul role="tablist" className="nav flex-column dashboard-list">
                    <li>
                      <a
                        href="#dashboard"
                        data-bs-toggle="tab"
                        className="nav-link active"
                      >
                        Dashboard
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="#orders"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        Orders
                      </a>
                    </li>

                    <li>
                      <a onClick={logOutAdmin} className="nav-link">
                        logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-9 col-lg-9">
                <div className="tab-content dashboard_content">
                  <div className="tab-pane fade show active" id="dashboard">
                    <div className="dashboard_tab_button">
                      <ul
                        role="tablist"
                        className="nav flex-row dashboard-list"
                      >
                        <li>
                          <a
                            // href="#dashboard"
                            data-bs-toggle="tab"
                            className={
                              first == "Requested"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => {
                              productstatus("Requested");
                            }}
                          >
                            Requested
                          </a>
                        </li>
                        {/* <li>
                          {" "}
                          <a
                            // href="#orders"
                            data-bs-toggle="tab"
                            className={
                              first == "Approved"
                                ? "nav-link ms-2 active"
                                : "nav-link ms-2"
                            }
                            onClick={() => {
                              productstatus("Approved");
                            }}
                          >
                            Approved
                          </a>
                        </li> */}
                        <li>
                          {" "}
                          <a
                            // href="#orders"
                            data-bs-toggle="tab"
                            className={
                              first == "Replied"
                                ? "nav-link ms-2 active"
                                : "nav-link ms-2"
                            }
                            onClick={() => {
                              productstatus("Replied");
                            }}
                          >
                            Replied
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Query Id</th>
                            <th>Order Id</th>
                            <th>Query Date</th>

                            <th>Status</th>
                            <th>estimate delivery date</th>
                            {/* <th>Total</th> */}
                            <th>description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {get_guery_by_status &&
                            get_guery_by_status?.map((data, i) => {
                              return (
                                <tr key={i}>
                                  <td> kpq {data?.id}</td>
                                  <td> {data?.order_id}</td>
                                  <td>{data?.created_at}</td>

                                  <td>
                                    <span className="success">
                                      {data?.status}
                                    </span>
                                  </td>
                                  <td>{data?.estimate_delivery_date}</td>
                                  <td>{data?.description}</td>
                                  {/* <td>₹{data?.amount}</td> */}
                                  <td>
                                    {/* Replied */}
                                    {data?.status == "Replied" && (
                                      <a
                                        onClick={() => {
                                          navigate(
                                            "/order-details/" + data?.order_id
                                          );
                                        }}
                                        className="view"
                                      >
                                        view
                                      </a>
                                    )}
                                  </td>
                                  {/* <p>some cene</p> */}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <p>
                      From your account dashboard. you can easily check &amp;
                      view your <a href="#">recent orders</a>, manage your{" "}
                      <a href="#">shipping and billing addresses</a> and{" "}
                      <a href="#">Edit your password and account details.</a>
                    </p>
                  </div>
                  <div className="tab-pane fade" id="orders">
                    <h3>Orders</h3>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Order</th>

                            <th>Status</th>
                            <th>Total</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {get_order &&
                            get_order?.map((data, i) => {
                              console.log(data);
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>

                                  <td>
                                    <span className="success">
                                      {data?.order_status}
                                    </span>
                                  </td>
                                  <td>₹{data?.amount}</td>
                                  <td>
                                    {data?.order_status == "Ordered" && (
                                      <a
                                        onClick={() => {
                                          navigate(
                                            "/order-details/" + data?.id
                                          );
                                        }}
                                        className="view"
                                      >
                                        view
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="downloads">
                    <h3>Downloads</h3>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Downloads</th>
                            <th>Expires</th>
                            <th>Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Shopnovilla - Free Real Estate PSD Template</td>
                            <td>May 10, 2018</td>
                            <td>
                              <span className="danger">Expired</span>
                            </td>
                            <td>
                              <a href="#" className="view">
                                Click Here To Download Your File
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Organic - ecommerce html template</td>
                            <td>Sep 11, 2018</td>
                            <td>Never</td>
                            <td>
                              <a href="#" className="view">
                                Click Here To Download Your File
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane" id="address">
                    <p>
                      The following addresses will be used on the checkout page
                      by default.
                    </p>
                    <h4 className="billing-address">Billing address</h4>
                    <a href="#" className="view">
                      Edit
                    </a>
                    <p>
                      <strong>Bobby Jackson</strong>
                    </p>
                    <address>
                      <span>
                        <strong>City:</strong> Seattle
                      </span>
                      ,
                      <br />
                      <span>
                        <strong>State:</strong> Washington(WA)
                      </span>
                      ,
                      <br />
                      <span>
                        <strong>ZIP:</strong> 98101
                      </span>
                      ,
                      <br />
                      <span>
                        <strong>Country:</strong> USA
                      </span>
                    </address>
                  </div>

                  <div className="tab-pane fade" id="account-details">
                    <h3>Account details </h3>
                    <div className="login">
                      <div className="login_form_container">
                        <div className="account_login_form">
                          <form action="#">
                            <p>
                              Already have an account?{" "}
                              <a href="#">Log in instead!</a>
                            </p>
                            <div className="input-radio">
                              <span className="custom-radio">
                                <input
                                  type="radio"
                                  value="1"
                                  name="id_gender"
                                />{" "}
                                Mr.
                              </span>
                              <span className="custom-radio">
                                <input
                                  type="radio"
                                  value="1"
                                  name="id_gender"
                                />{" "}
                                Mrs.
                              </span>
                            </div>{" "}
                            <br />
                            <label>First Name</label>
                            <input type="text" name="first-name" />
                            <label>Last Name</label>
                            <input type="text" name="last-name" />
                            <label>Email</label>
                            <input type="text" name="email-name" />
                            <label>Password</label>
                            <input type="password" name="user-password" />
                            <label>Birthdate</label>
                            <input
                              type="text"
                              placeholder="MM/DD/YYYY"
                              value=""
                              name="birthday"
                            />
                            <span className="example">(E.g.: 05/31/1970)</span>
                            <br />
                            <span className="custom_checkbox">
                              <input type="checkbox" value="1" name="optin" />
                              <label>Receive offers from our partners</label>
                            </span>
                            <br />
                            <span className="custom_checkbox">
                              <input
                                type="checkbox"
                                value="1"
                                name="newsletter"
                              />
                              <label>
                                Sign up for our newsletter
                                <br />
                                <em>
                                  You may unsubscribe at any moment. For that
                                  purpose, please find our contact info in the
                                  legal notice.
                                </em>
                              </label>
                            </span>
                            <div className="save_button primary_btn default_button">
                              <a href="#">Save</a>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Account;
