import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ShoppingCards from "../components/ShoppingCards/ShoppingCards";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

const SweetSale = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <ShoppingCards />
      {/* <ShoppingCards />
      <ShoppingCards />
      <ShoppingCards /> */}

      <Footer />
    </>
  );
};

export default SweetSale;
