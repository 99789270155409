import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useHistory } from "react-router-dom";
import { allUserRegisterAction } from "../../Redux/common/action";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const history = useHistory()

  const [loginShow, setLoginShow] = useState("0");

  const [values, setValue] = useState({
    user_name: "",
    user_mobile: "",
    user_email: "",
    password: "",
  });
  const [loginValue, setloginValue] = useState({
    mobile_number: "",
    password: "",
  });
  const [otp, setOtp] = useState("");
  const [otpShow, setOtpShow] = useState("0");

  // login

  const login = useSelector((state) =>
    state?.allUserRegister?.login ? state?.allUserRegister?.login : {}
  );

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate(-1);
    }
  }, [login]);
  // navigate("/")

  const userRegister = (e) => {
    dispatch(
      allUserRegisterAction.send_otp({ user_mobile: values?.user_mobile })
    );
    setOtpShow("1");
    return () => {};
  };

  const userlogin = (e) => {
    dispatch(allUserRegisterAction.login(loginValue, navigate));
    // setOtpShow("1")
    return () => {};
  };

  const useOtp = (e) => {
    dispatch(
      allUserRegisterAction.otpverify(
        {
          user_mobile: values?.user_mobile,
          user_name: values?.user_name,
          user_email: values?.user_email,
          password: values?.password,
          otp: otp,
        },
        navigate
      )
    );
    return () => {};
  };

  const hendleRegister = (e) => {
    const { name, value } = e.target;
    setValue({ ...values, [name]: value });
  };

  const hendleLogin = (e) => {
    const { name, value } = e.target;
    setloginValue({ ...loginValue, [name]: value });
  };

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="customer_login mv-100">
        <div className="container">
          <div className="row">
            {loginShow == "0" ? (
              <div className="col-lg-12 col-md-12">
                <div className="account_form">
                  <h2>login</h2>

                  <p>
                    <label>
                      Number<span>*</span>
                    </label>
                    <input
                      type="number"
                      name="mobile_number"
                      onChange={(e) => {
                        hendleLogin(e);
                      }}
                      value={loginValue?.mobile_number}
                    />
                  </p>
                  <p>
                    <label>
                      Password <span>*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={loginValue?.password}
                      onChange={(e) => {
                        hendleLogin(e);
                      }}
                    />
                  </p>
                  <div className="login_submit">
                    <button
                      type="submit"
                      onClick={(e) => {
                        userlogin(e);
                      }}
                    >
                      login
                    </button>
                  </div>

                  <p
                    className="changeLog"
                    onClick={(e) => {
                      setLoginShow("1");
                    }}
                  >
                    If you are not Registered ? Click Here
                  </p>
                </div>
              </div>
            ) : (
              <div className="col-lg-12 col-md-12">
                {otpShow == "0" ? (
                  <div className="account_form register">
                    <h2>Register</h2>

                    <p>
                      <label>
                        Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="user_name"
                        value={values?.user_name}
                        onChange={(e) => {
                          hendleRegister(e);
                        }}
                      />
                    </p>
                    <p>
                      <label>
                        Number <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="user_mobile"
                        value={values?.user_mobile}
                        onChange={(e) => {
                          hendleRegister(e);
                        }}
                      />
                    </p>
                    <p>
                      <label>
                        Email address <span>*</span>
                      </label>
                      <input
                        type="email"
                        name="user_email"
                        value={values?.user_email}
                        onChange={(e) => {
                          hendleRegister(e);
                        }}
                      />
                    </p>
                    <p>
                      <label>
                        Passwords <span>*</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={values?.password}
                        onChange={(e) => {
                          hendleRegister(e);
                        }}
                      />
                    </p>
                    <div className="login_submit">
                      <button type="submit" onClick={userRegister}>
                        Register
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="account_form register">
                    <p>
                      <label>
                        Otp <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="otp"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                      />
                    </p>
                    <div className="login_submit">
                      <button type="submit" onClick={useOtp}>
                        Register
                      </button>
                    </div>
                  </div>
                )}
                <p
                  className="changeLog"
                  onClick={(e) => {
                    setLoginShow("0");
                  }}
                >
                  Already Registered ? Click Login
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
