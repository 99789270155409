import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "../common/api";

import {
  LOADING,
  HOME_DATA,
  VIEW_CART,
  INCRESS_QTY,
  DECRESS_QTY,
  DELETE_FROM_CART,
  ADD_CART,
  CATEGORY_PRODUCT,
  GET_CATEGORY,
  PRODUCT_DETAILS,
  SEARCH_PRODUCT,
  ADD_ADDRESS,
  GET_CITY,
  GET_ADDRESS,
  BOOK_ORDER,
  GET_ORDER_BY_ID,
  DELETE_ADDRESS,
  GET_ORDER,
  FATCH_PRO,
  CART_ITME_ADD,
  CHECK_PINCODE,
  SHIPPING_CHARGE,
  CHECK_DISCOUNT_CODE,
  STATIC_PAGES,
  CANCLE_ORDER,
  GET_QUERY_BY_STATUS,
} from "../common/constant";
import Token from "../../Utils/Auth/Token";

//  const config = {
//   headers: {
//     Authorization: "Bearer " + Token.getToken(),
//   },
// };

const loading = (data) => {
  return { type: LOADING, payload: data };
};
const homeDataAction = (data) => {
  return { type: HOME_DATA, payload: data };
};
const viewcartAction = (data) => {
  return { type: VIEW_CART, payload: data };
};
const incressqtyAction = (data) => {
  return { type: INCRESS_QTY, payload: data };
};
const decressqtyAction = (data) => {
  return { type: DECRESS_QTY, payload: data };
};
const deletecartAction = (data) => {
  return { type: DELETE_FROM_CART, payload: data };
};
const addcartAction = (data) => {
  return { type: ADD_CART, payload: data };
};
const categoryProductAction = (data) => {
  return { type: CATEGORY_PRODUCT, payload: data };
};
const getcategoryAction = (data) => {
  return { type: GET_CATEGORY, payload: data };
};
const productdetaisAction = (data) => {
  return { type: PRODUCT_DETAILS, payload: data };
};
const searchProductAction = (data) => {
  return { type: SEARCH_PRODUCT, payload: data };
};
const addAddressAction = (data) => {
  return { type: ADD_ADDRESS, payload: data };
};
const getAddressAction = (data) => {
  return { type: GET_ADDRESS, payload: data };
};
const getCityAction = (data) => {
  return { type: GET_CITY, payload: data };
};
const bookOrderAction = (data) => {
  return { type: BOOK_ORDER, payload: data };
};
const getOrderbyIdAction = (data) => {
  return { type: GET_ORDER_BY_ID, payload: data };
};
const getOrderAction = (data) => {
  return { type: GET_ORDER, payload: data };
};
const deleteaddressAction = (data) => {
  return { type: DELETE_ADDRESS, payload: data };
};
const fatchAction = (data) => {
  return { type: FATCH_PRO, payload: data };
};

const cartItmeaddAction = (data) => {
  return { type: CART_ITME_ADD, payload: data };
};

const checkPincodeAction = (data) => {
  return { type: CHECK_PINCODE, payload: data };
};
const shipingchargeAction = (data) => {
  return { type: SHIPPING_CHARGE, payload: data };
};
const checkdiscountAction = (data) => {
  return { type: CHECK_DISCOUNT_CODE, payload: data };
};
const staticPagesAction = (data) => {
  return { type: STATIC_PAGES, payload: data };
};
const cancleorderAction = (data) => {
  return { type: CANCLE_ORDER, payload: data };
};
const get_query_by_statusAction = (data) => {
  return { type: GET_QUERY_BY_STATUS, payload: data };
};

export const get_query_by_status = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.get_query_by_status}?status=${data}`,
        config
      );

      dispatch(get_query_by_statusAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gethomedata = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.home}`, config);

      dispatch(homeDataAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getviewcartdata = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.view_cart}`, config);

      dispatch(viewcartAction(response?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getAddressdata = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.get_address}`, config);

      dispatch(getAddressAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const searchProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.search_product}`, data, config);

      dispatch(searchProductAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const staticPages = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.static_pages}`, data);

      dispatch(staticPagesAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const addAddress = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.add_address}`, data, config);
      dispatch(addAddressAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const cancleorder = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token2 = "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.cancle_order}`, data, config);

      if (response?.data?.success) {
        navigate("/account");
        toast.success(response?.data?.message)
      }

      dispatch(cancleorderAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getCity = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.get_city}`, data, config);
      dispatch(getCityAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const incressQty = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.incress_qty}`, data, config);

      dispatch(incressqtyAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const decressQty = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.decress_qty}`, data, config);

      dispatch(decressqtyAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteCartItme = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.delete_from_cart}`, data, config);

      // console.log(response?.data?.message);

      dispatch(deletecartAction(response?.data?.data));
      toast.success(response?.data?.message);
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const addCartItme = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.add_cart}`, data, config);

      // console.log(response?.data);

      dispatch(addcartAction(response?.data?.data));
      toast.success(response?.data?.message);
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getCategoryProduct = ({ data, type }) => {


  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      var response = {};


      if (type !== '') {
        response = await api.post(`${URL.filter_category_product}`, data, config);
      }
      else {
        response = await api.post(`${URL.category_product}`, data, config);
      }


      dispatch(categoryProductAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getProductDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.product_details}`, data, config);

      dispatch(productdetaisAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.get_category}`, config);

      dispatch(getcategoryAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const bookOrder = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.book_order}`, data, config);

      if (response?.data?.success == true) {
        dispatch(bookOrderAction(response?.data));

        toast.success(response?.data?.message);
        dispatch(
          checkdiscountAction({
            success: false,
          })
        );

        navigate("/account");
      } else {
        toast.error(response?.data?.message);
      }

      // userSendemail(response?.data?.data)
      // adminSendemail(response?.data?.data)
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const update_order = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_order}`, data, config);

      if (response?.data?.success == true)
        // dispatch(update_orderAction(response?.data));

        toast.success(response?.data?.message);
      // dispatch(
      //   checkdiscountAction({
      //     success: false,
      //   })
      // );
      // navigate("/account");
      // userSendemail(response?.data?.data)
      // adminSendemail(response?.data?.data)
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

const userSendemail = (e) => {
  const userEmail = localStorage.getItem("access_email");
  const username = localStorage.getItem("access_name");

  var emailData = {
    service_id: "service_1rbho8f",
    template_id: "template_czzv9vr",
    user_id: "6__X8V1RhwkxQrHm5",
    template_params: {
      name: username,
      email: userEmail,
      message: "Your Order Successfully Placed.",
      order_id: "your order id = " + e?.id,
    },
  };

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({});
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(emailData),
    redirect: "follow",
  };

  fetch("https://api.emailjs.com/api/v1.0/email/send", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result === "Successfully") {
      } else {
      }
    });
};

const adminSendemail = (e) => {
  const userEmail = localStorage.getItem("access_email");
  const username = localStorage.getItem("access_name");

  var emailData = {
    service_id: "service_1rbho8f",
    template_id: "template_gmncu3f",
    user_id: "6__X8V1RhwkxQrHm5",
    template_params: {
      name: username,
      email: userEmail,
      message: "Your Order Successfully Placed.",
      order_id: "your order id = " + e?.id,
      order1_id: "user id = " + e?.user_id,
    },
  };

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({});
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(emailData),
    redirect: "follow",
  };

  fetch("https://api.emailjs.com/api/v1.0/email/send", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result === "Successfully") {
        console.log(result);
      } else {
        console.log(result);
      }
    });
};

export const getOrderbyId = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.get_order_by_id}`, data, config);

      dispatch(getOrderbyIdAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getOrder = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.get_order}`, config, data);
      dispatch(getOrderAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteAddress = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.delete_address}`, data, config);

      dispatch(deleteaddressAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const fatchPro = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.fatch_pro}`, data, config);

      dispatch(fatchAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const checkPincode = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.check_pincode}`, data, config);
      dispatch(checkPincodeAction(response?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const checkdiscountcode = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.check_discount_code}`,
        data,
        config
      );
      dispatch(checkdiscountAction(response?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const shippingcharges = (data, history) => {
  return async (dispatch) => {
    try {
      const token2 = await "oReBfOy3UbkjJTtQWEXdriawM708mvZP1c9qGIDzlVN56FYKC";
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.shipping_charges}`, config);
      dispatch(shipingchargeAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const addcartItme = (data) => {
  return async (dispatch) => {
    try {
      // dispatch(loading(true));
      // const response = await api.post(`${URL.fatch_pro}`, data, config);

      dispatch(cartItmeaddAction(data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
