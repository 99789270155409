import React from "react";
import Slider from "react-slick";
function Highlight() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrow: false,
    autoplay: true,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container mv-100">
      <div className="row">
        <div className="col-12">
          <div className="breadcrumb_content Text">
            <h3 className="Text"   style={{ fontWeight: 'bold', fontSize: '40px', color: 'gray' }}>HIGHLIGHTS</h3>
          </div>
        </div>
      </div>

      <Slider {...settings}>
        <div className="custom-col-5 mt-15 Text">
          <div className="single_product prohg">
            <div className="product_content highLight Text">
              <h3 className="Text" style={{fontSize:"20px"}}>Customization
              </h3>
              <p className="Text">We customize products as per your need.</p>
              {/* <img src="/assets/highlight/3.webp" alt="" /> */}

              {/* <p>Handmade Product</p> */}
            </div>
          </div>
        </div>
        <div className="custom-col-5 mt-15">
          <div className="single_product prohg">
            <div className="product_content highLight">
              <h3  className="Text" style={{fontSize:"20px"}}>Handcrafted products
              </h3>
              <p className="Text">Our products are beautifully handcrafted by Jaipur women.</p>
              {/* <img src="/assets/highlight/3.webp" alt="" /> */}

              {/* <p>Handmade Product</p> */}
            </div>
          </div>
        </div>
        <div className="custom-col-5 mt-15">
          <div className="single_product prohg">
            <div className="product_content highLight">
              <h3 className="Text" style={{fontSize:"20px"}}>Social Enterprise
              </h3>
              <p className="Text">
                We are a social enterprise which believes in the voice of Vocal
                for Local.
              </p>
              {/* <img src="/assets/highlight/3.webp" alt="" /> */}

              {/* <p>Handmade Product</p> */}
            </div>
          </div>
        </div>

        <div className="custom-col-5  mt-15">
          <div className="single_product prohg">
            <div className="product_content highLight">
              <h3  className="Text"  style={{fontSize:"20px"}}>Customer Service
              </h3>
              <p className="Text">
                Our whatsapp support team is available 24/7 - Isn't that amazing{" "}
              </p>
              {/* <img src="/assets/highlight/4.webp" alt="" />

              <p>Whatsapp support</p> */}
            </div>
          </div>
        </div>
        <div className="custom-col-5  mt-15">
          <div className="single_product prohg">
            <div className="product_content highLight">
              <h3 className="Text"  style={{fontSize:"20px"}}>
                Worldwide Shipping
              </h3>
              <p className="Text">
                We accept international orders & are usually delivered within 3
                weeks!
              </p>
              {/* <img src="/assets/highlight/1.webp" alt="" />

              <p>Deliver international</p> */}
            </div>
          </div>
        </div>
        <div className="custom-col-5  mt-15">
          <div className="single_product prohg">
            <div className="product_content highLight">
              <h3 className="Text" style={{fontSize:"20px"}}>Exclusive Delivery
              </h3>
              <p className="Text"> Shipping charges are to be borne by customers.</p>
              {/* <img src="/assets/highlight/2.webp" alt="" />

              <p>Delivery within India</p> */}
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Highlight;
