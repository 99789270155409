

const setToken = (access_token) => {
    localStorage.setItem("access_token", access_token);
  
    return true;
  };
  
  
  const setVendorToken = (access_token) => {
    localStorage.setItem("access_token_vendor", access_token);
    return true;
  };
  
  const getToken =( () => {
    const token =  localStorage.getItem("access_token");
  
    if (token) {
      return token;
    } else {
      return false;
    }
  });
  
  
  const setUserName = (access_name) => {
    localStorage.setItem("access_name", access_name);
  
    return true;
  };
  const setUserEmail = (access_email) => {
    localStorage.setItem("access_email", access_email);
  
    return true;
  };
  
  const setUserNumber = (access_number) => {
    localStorage.setItem("access_number", access_number);
  
    return true;
  };
  
  
  
  const getUserName = () => {
    const userDeatail = localStorage.getItem("access_name");
  
    if (userDeatail) {
      return userDeatail;
    } else {
      return false;
    }
  };
  
  
  
  const setUserDetail = (access_user) => {
    localStorage.setItem("access_user", access_user);
  
    return true;
  };
  
  const getUserDetail = () => {
    const userDeatail = localStorage.getItem("access_user");
  
    if (userDeatail) {
      return userDeatail;
    } else {
      return false;
    }
  };
  
  
  
  
  
  const getUserEmail = () => {
    const userDeatail = localStorage.getItem("access_email");
  
    if (userDeatail) {
      return userDeatail;
    } else {
      return false;
    }
  
  };
  const getUserNumber = () => {
    const userDeatail = localStorage.getItem("access_number");
  
    if (userDeatail) {
      return userDeatail;
    } else {
      return false;
    }
  };
  
  module.exports = {
    setToken,
    getToken,
    setVendorToken,
    setUserName,
    setUserDetail,
    getUserDetail,
    setUserEmail,
    setUserNumber,
    getUserName,
    getUserEmail,
    getUserNumber,
  
  
  };
  