import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import Footer from "../../Component/Footer/Footer";
// import Header from "../../Component/Header/Header";
import { productsAction } from "../../Redux/common/action";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
// import ScrollToTop from "../../ScrollToTop/ScrollToTop";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = document.domain === "localhost";

function OrderDetails() {
  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const product = useSelector((state) =>
    state?.product?.get_order_by_id ? state?.product?.get_order_by_id : {}
  );

  const get_address = useSelector((state) =>
    state?.product?.get_address ? state?.product?.get_address : {}
  );

  const [Popp, setPop] = useState("0");
  const [Popp1, setPop1] = useState("0");

  // cancleorder

  const [first, setfirst] = useState({
    cancle_reason: "",
    specify_reason: "",
  });

  const hedledata = (e) => {
    const { name, value } = e.target;

    setfirst({ ...first, [name]: value });
  };

  const cencilOderHendle = (e) => {
    if (!first?.cancle_reason) {
      toast.error("Please enter Cancle reason");
    } else if (!first?.specify_reason) {
      toast.error("Please enter Specify reason");
    } else {
      setPop("0");
      // document.querySelector("#addSetting").data-bs-dismiss = "modal";

      const datas = new FormData();
      datas.append("order_id", id?.id);
      datas.append("cancle_reason", first?.cancle_reason);
      datas.append("cancle_reason", first?.cancle_reason);
      dispatch(productsAction.cancleorder(datas,navigate));
      // navigate(-1);
      return () => {};
    }
  };

  const hendlePop = (e) => {
    setPop(e);
  };
  const hendlePop1 = (e) => {
    setPop1(e);
  };

  useEffect(() => {
    const datas = new FormData();
    datas.append("order_id", id?.id);

    dispatch(productsAction.getOrderbyId(datas));
    dispatch(productsAction.getAddressdata());
    return () => {};
  }, [id?.id]);

  console.log(product);

  // const dToatal = product?.products.reduce(
  //   (total, obj) => parseInt(obj.amount) + total,
  //   0
  // );

  const totalAmoutss = parseInt(product?.amount) + parseInt(product?.shipping);

  // const totalAmoutss = 20;

  var item_value = sessionStorage.getItem("item_key");
  // console.log(item_value);

  var userAllDetals = JSON.parse(item_value);
  // console.log(userAllDetals);

  async function displayRazorpay(e) {
    console.log("test");

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const totalAmount = totalAmoutss;

    const amountDetails = {
      amounts: totalAmount,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify(amountDetails),
      redirect: "follow",
    };

    // const data = await fetch('http://localhost:5000/razorpay', requestOptions).then((t) =>
    //     t.json()
    // )

    // const  data = await axios.post('http://192.168.0.65:5500/razorpay', amountDetails, {
    //   headers : {
    //     'Content-Type': 'text/plain'
    //   }
    // }).then((t) =>
    //  t.json()
    //   )

    const options = {
      key: /*  __DEV__ ? */ "rzp_test_4Wrr7LEnAa53c0" /* : 'PRODUCTION_KEY' */,
      currency: "INR",
      amount: 100 * totalAmount.toString(),
      // order_id: data.id,
      name: "Kafi Pyar",
      description: "Thank you for nothing. Please give us some money",
      image: "/static/media/Logo.63d06c232f6a54a6f911.png",
      handler: (res) => {
        const datas = new FormData();
        datas.append("order_id", id?.id);
        datas.append("transection_id", res?.razorpay_payment_id);
        datas.append("payment_method", "Razorpay");

        dispatch(productsAction.update_order(datas, navigate));

        setTimeout(() => {
          const datass = new FormData();
          datass.append("order_id", id?.id);

          dispatch(productsAction.getOrderbyId(datass));
        }, 1000);
      },

      // prefill: {
      //   // name: address?.receiver_name,
      //   // email: address?.email,
      //   // contact: address?.receiver_mobile,
      // },
      prefill: {
        name: userAllDetals?.full_name,
        email: userAllDetals?.full_name,
        contact: userAllDetals?.mobile_number,
      },
      notes: {
        userId: userAllDetals?.user_id,
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    paymentObject.open();
  }

  const [PaymentType, setPaymentType] = useState("");

  const heldePayment = (e) => {
    setPaymentType(e);
  };

  const hendlePayments = (e) => {
    if (PaymentType == "RazarPay") {
      displayRazorpay();
      hendlePop1("0");
    } else if (PaymentType == "COD") {
      hendlePayment();
      hendlePop1("0");
    } else {
      toast.error("choose a payment method");
    }
  };

  const hendlePayment = (e) => {
    // if (check_pincode?.success == true) {
    const datas = new FormData();
    datas.append("order_id", id?.id);
    // datas.append("amount", "totalAmount");
    datas.append("derlivery_charge", "COD");

    dispatch(productsAction.update_order(datas, navigate));

    setTimeout(() => {
      const datass = new FormData();
      datass.append("order_id", id?.id);

      dispatch(productsAction.getOrderbyId(datass));
    }, 1000);
    return () => {};
    // } else {
    // toast.error(check_pincode?.message);
    // }
  };

  console.log(product);

  return (
    <div>
      <ScrollToTop />
      <Header />
      <div className="breadcrumbs_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_content">
                <h3>Order Details</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <div className="orderDe row">
            <div className="col-12 col-sm-3">
              <div className="row">
                <h5 className="col-6"> Amount : </h5>
                <h5 className="col-6">₹{product?.amount}</h5>
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="row">
                <h5 className="col-6"> Order Status : </h5>
                <h5 className="col-6">{product?.order_status}</h5>
              </div>
            </div>
            {/* <div className="col-12 col-sm-3">
              <div className="row">
                <h5 className="col-6"> Payment Method:</h5>
                <h5 className="col-6">{product?.payment_method}</h5>
              </div>
            </div> */}
            <div className="col-12 col-sm-3">
              <div className="row">
                <h5 className="col-6"> Discount : </h5>
                <h5 className="col-6">{product?.discount}</h5>
              </div>
            </div>
            <div className="col-12 col-sm-3">
              <div className="row">
                <h5 className="col-6"> Shipping : </h5>
                <h5 className="col-6">{product?.shipping}</h5>
              </div>
            </div>
          </div>

          <div className="row justify-content-between p-5">
            <div className="col">
              <h3>Your Order Product</h3>
            </div>

            <div className="col">
              {product?.order_status == "Ordered" ||
              product?.order_status == "Cancelled" ? (
                ""
              ) : (
                <button
                  type="button"
                  class="btn btn-success"
                  data-bs-target="#exampleModal"
                  onClick={(e) => {
                    displayRazorpay("1");
                  }}
                >
                  Complete Order
                </button>
              )}
            </div>

            <div className="col">
              {product?.order_status == "Ordered" ||
              product?.order_status == "Cancelled" ? (
                ""
              ) : (
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-target="#exampleModal"
                  onClick={(e) => {
                    hendlePop("1");
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>

          <div className="row mv-50">
            {product?.products &&
              product?.products?.map((data, i) => {
                return (
                  <div className="col-12 col-md-6">
                    <div className="single_product product_list_item">
                      <div className="row">
                        <div className="col-lg-4 col-md-5">
                          <div className="product_thumb">
                            <a
                              className="primary_img"
                              onClick={() => {
                                navigate(
                                  "/product-details/" + data?.product_id
                                );
                              }}
                            >
                              <img src={data?.image} alt="" />
                            </a>
                            <a
                              className="secondary_img"
                              onClick={() => {
                                navigate(
                                  "/product-details/" + data?.product_id
                                );
                              }}
                            >
                              <img src={data?.image} alt="" />
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-7">
                          <div className="product_content">
                            <h3>
                              <a
                                onClick={() => {
                                  navigate(
                                    "/product-details/" + data?.product_id
                                  );
                                }}
                              >
                                {data?.product_name}
                              </a>
                            </h3>

                            <div className="product_desc"></div>
                            <div className="price_box">
                              <span className="current_price">
                                ₹{data?.price} * {data?.cart_qty}
                              </span>
                              <p className="current_price">₹{data?.amount}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button> */}

      <div
        class={Popp1 == "1" ? "modal fade show" : "modal fade"}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        style={Popp1 == "1" ? { display: "block" } : { display: "none" }}
        aria-modal={Popp1 == "1" ? "true" : "false"}
        aria-hidden={Popp1 == "0" ? "true" : "false"}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Order Complete
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={(e) => {
                  hendlePop1("0");
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Select payment mode
              <div className="account_form">
                {/* <h2>login</h2> */}
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="payment_method">
                      {/* <div className="panel-default">
                        <input
                          id="payment"
                          name="payment_method"
                          type="radio"
                          value={"COD"}
                          onClick={(e) => heldePayment(e.target.value)}
                          data-bs-target="createp_account"
                        />
                        <label
                          for="payment"
                          data-bs-toggle="collapse"
                          data-bs-target="#method"
                          aria-controls="method"
                        >
                          cash on delivery
                        </label>
                      </div> */}
                      <div className="panel-default">
                        <input
                          id="payment_defult"
                          name="payment_method"
                          value={"RazarPay"}
                          onClick={(e) => heldePayment(e.target.value)}
                          type="radio"
                          data-bs-target="createp_account"
                        />
                        <label
                          for="payment_defult"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsedefult"
                          aria-controls="collapsedefult"
                        >
                          Online{" "}
                          {/* <img src="assets/img/icon/papyel.png" alt="" /> */}
                        </label>
                      </div>
                      <div className="order_button">
                        <button onClick={hendlePayments}>Continue</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={cencilOderHendle}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  hendlePop1("0");
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class={Popp == "1" ? "modal fade show" : "modal fade"}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        style={Popp == "1" ? { display: "block" } : { display: "none" }}
        aria-modal={Popp == "1" ? "true" : "false"}
        aria-hidden={Popp == "0" ? "true" : "false"}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Cencil Order
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={(e) => {
                  hendlePop("0");
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Are you sure to cencil this order
              <div className="account_form">
                {/* <h2>login</h2> */}

                <p>
                  <label>
                    cancle reason<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="cancle_reason"
                    value={first?.cancle_reason}
                    onChange={(e) => {
                      hedledata(e);
                    }}
                  />
                </p>
                <p>
                  <label>
                    specify reason <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="specify_reason"
                    value={first?.specify_reason}
                    onChange={(e) => {
                      hedledata(e);
                    }}
                  />
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                onClick={cencilOderHendle}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  hendlePop("0");
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default OrderDetails;
