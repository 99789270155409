import React from "react";
import Slider from "react-slick";

function Testimonial() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <section className="section testimonial-section">
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-7">
              <h2 className="heading Text" data-aos="fade-up"  style={{ fontWeight: 'bold', fontSize: '40px', color: 'gray' }}>
              LOVE LETTERS
              </h2>
            </div>
          </div>
          <div></div>
          {/* <div className="row"> */}

          <Slider {...settings}>
            <div className="testimonial text-center slider-item">
              {/* <div className="author-image mb-3">
                <img
                  src="/assets/img/psr3.jpeg"
                  alt="Image placeholder"
                  className="rounded-circle mx-auto"
                />
              </div> */}
              <blockquote>
                <p className="Text fs-4">
                  &ldquo;I run a gift hampers business, based in Jalandhar. I
                  came across Kafi Pyar’s profile on Instagram and it was super
                  appealing. I ended up ordering various types of potlis, which
                  eventually became the highlight of my fusion hampers and
                  Diwali hampers. Their quality is awesome, variety is amazing,
                  and prices are reasonable. I am a forever client! Way to go,
                  Ananya & team! &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash; Gulnaar</em>
              </p>
            </div>

            <div className="testimonial text-center slider-item">
              <blockquote>
                <p className="Text fs-4">
                  &ldquo;I had been on the lookout for some appealing and
                  elegant potli bags and accessories at a reasonable for my
                  wedding and stumbled upon KafiPyar. Their products are very
                  pretty and affordable and the process of placing an order also
                  was very simple. Ananya (entrepreneur) was very approachable
                  and happy to customize the order based on my requirements
                  which was great! Will definitely recommend KafiPyar products!
                  &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash; Bhakti</em>
              </p>
            </div>
            <div className="testimonial text-center slider-item">
              <blockquote>
                <p className="Text fs-4">
                  &ldquo;Excellent work and quite prompt and very helpful. did
                  my Diwali order at a very short notice and as per requirement
                  &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash; vaishali vaghela</em>
              </p>
            </div>
            <div className="testimonial text-center slider-item">
              <blockquote>
                <p className="Text fs-4">
                  &ldquo;I had placed my order twice from Kafi pyaar, one on my
                  brother's wedding on teej...Her products are of very good
                  quality n best thing is her products are reasonable among
                  other sellers on Instagram or even from Chandni
                  chowk....Ananya is very dedicated n honest towards her
                  work...Good luck Ananya...God bless you &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash; Nidhi Aggarwal</em>
              </p>
            </div>
            <div className="testimonial text-center slider-item">
              <blockquote>
                <p className="Text fs-4">
                  &ldquo;We had a great experience ordering from Ananya. The
                  quality of the potlis was great, just like in the pictures.
                  Moreover, ours was a bulk order and we had limited time,
                  Ananya went out of her way to deliver the order through
                  personal transport ensuring that it reached us on time!
                  Recommend. &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash; Vandana Gupta</em>
              </p>
            </div>
            <div className="testimonial text-center slider-item">
              <blockquote>
                <p className="Text fs-4">
                  &ldquo;We came across Kafi pyaar on Instagram. Ananya is very
                  dedicated at what she does and went out of her way to arrange
                  our return gifts. The gifts too were of great quality and even
                  took returns and exchanges them when we felt the product was
                  not satisfactory. Overall, she is trustworthy and reasonable.
                  &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash; Mamtha</em>
              </p>
            </div>
            <div className="testimonial text-center slider-item">
              <blockquote>
                <p className="Text fs-4">
                  &ldquo;I had been looking for beautiful but reasonable potlis
                  and jewellery boxes for my sister's wedding and Kafi Pyar was
                  recommended to me by a friend. Even though it seems to be a
                  relatively new business, the quality of the products received
                  as well as the customer service was top notch! I got to choose
                  from a massive portfolio of different products in varying
                  cloths, colours, sizes and styles while placing my order. They
                  also delivered on short notice. Would 10/10 recommend -
                  especially if you want that Rajasthani touch ; &rdquo;
                </p>
              </blockquote>
              <p>
                <em>&mdash; Meghna</em>
              </p>
            </div>
            {/* <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the
                    skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own
                    road, the Line Lane.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_1.jpg.pagespeed.ic.4PnkaDUwf1.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; Jean Smith</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_2.jpg.pagespeed.ic.1RospF5maY.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                    unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                    leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the
                    skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own
                    road, the Line Lane.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div> */}
          </Slider>

          {/* <div className="js-carousel-2 owl-carousel mb-5" data-aos="fade-up" data-aos-delay="200">
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_1.jpg.pagespeed.ic.4PnkaDUwf1.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; Jean Smith</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_2.jpg.pagespeed.ic.1RospF5maY.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                    unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                    leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the
                    skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own
                    road, the Line Lane.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_1.jpg.pagespeed.ic.4PnkaDUwf1.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; Jean Smith</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_2.jpg.pagespeed.ic.1RospF5maY.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;Even the all-powerful Pointing has no control about the blind texts it is an almost
                    unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to
                    leave for the far World of Grammar.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
              <div className="testimonial text-center slider-item">
                <div className="author-image mb-3">
                  <img src="images/xperson_3.jpg.pagespeed.ic.VBCdo69acS.jpg" alt="Image placeholder"
                    className="rounded-circle mx-auto" />
                </div>
                <blockquote>
                  <p>&ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the
                    skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own
                    road, the Line Lane.&rdquo;</p>
                </blockquote>
                <p><em>&mdash; John Doe</em></p>
              </div>
            </div> */}

          {/* </div> */}
        </div>
      </section>
    </div>
  );
}

export default Testimonial;
