import React, { useEffect, useMemo, useRef, useState } from "react";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../img/Logo.png";
import "../../App.css";
import CloseIcon from "@mui/icons-material/Login";
import { useDispatch, useSelector } from "react-redux";
import { productsAction } from "../../Redux/common/action";
import MenuOutSideClick from "./OutsideClick";
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const home_data = useSelector((state) =>
    state?.product?.home_data ? state?.product?.home_data : {}
  );
  const view_cart = useSelector((state) =>
    state?.product?.view_cart ? state?.product?.view_cart : {}
  );
  const add_cart = useSelector((state) =>
    state?.product?.add_cart ? state?.product?.add_cart : {}
  );
  const search_product = useSelector((state) =>
    state?.product?.search_product ? state?.product?.search_product : []
  );

  const delete_cart_itme = useSelector((state) =>
    state?.product?.delete_cart_itme ? state?.product?.delete_cart_itme : {}
  );
  const facth_pro = useSelector((state) =>
    state?.product?.facth_pro ? state?.product?.facth_pro : {}
  );
  const get_category = useSelector((state) =>
    state?.product?.get_category ? state?.product?.get_category : []
  );

  const add_cart_itme = useSelector((state) =>
    state?.product?.add_cart_itme ? state?.product?.add_cart_itme : ""
  );

  useEffect(() => {
    dispatch(productsAction.getCategory());
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(productsAction.getviewcartdata());
    return () => { };
  }, [add_cart, delete_cart_itme]);

  const blogIds = localStorage.getItem("cart_product");
  const blogIdss = blogIds !== null ? blogIds : "[]";
  const arrss = JSON.parse(blogIdss);

  const proId =
    arrss &&
    arrss?.map((data) => {
      return data?.product_id;
    });

  useEffect(() => {
    const datas = new FormData();
    datas.append("product_ids", [79, 80, 81]);

    if (!localStorage.getItem("access_token") && arrss?.length > 0) {
      dispatch(productsAction.fatchPro({ product_ids: proId }));
      return () => { };
    }
  }, []);

  var listsoff =
    facth_pro &&
    facth_pro?.filter((x) => {
      return x.product_id != add_cart_itme;
    });

  const commentsData = useMemo(() => {
    let computedComments =
      listsoff &&
      listsoff?.map((data, i) => {
        const lists =
          arrss &&
          arrss.filter((x) => {
            return x.product_id == data?.product_id;
          });

        if (lists == null) {
          const emptyArray = [];
          return emptyArray;
        } else {
          data.cart_qty = lists && lists[0]?.cart_qty;
          data.image = data.product_image;
          // data.amount =  data.price
          // data.var = lists && lists[0]?.variations
          return data;
        }
      });
    return computedComments;
  }, [arrss, add_cart_itme]);

  var totalAmount = 0;
  for (let index = 0; index < commentsData.length; index++) {
    const element = commentsData[index];

    if (element?.cart_qty !== undefined) {
      const tamount = element?.cart_qty * element?.price;
      totalAmount += tamount;
    }
  }

  useEffect(() => {
    if (
      localStorage.getItem("cart_product") &&
      localStorage.getItem("access_token")
    ) {
      //

      const blogIds = localStorage.getItem("cart_product");
      const blogIdss = blogIds !== null ? blogIds : "[]";
      const arrsss = JSON.parse(blogIdss);

      var ac = arrsss?.length;

      arrsss.forEach((file) => {
        const datas = new FormData();
        datas.append("product_id", file?.product_id);
        datas.append("qty", file?.cart_qty);
        dispatch(productsAction.addCartItme(datas));

        var lists = arrsss.filter((x) => {
          return x.product_id != file?.product_id;
        });

        if (arrsss?.length > 1) {
          ac -= 1;

          if (ac == 1) {
            localStorage.removeItem("cart_product");
          }
        } else {
          localStorage.removeItem("cart_product");
        }

        //  else {

        //     localStorage.setItem("cart_product", JSON.stringify(lists));
        // }
        // return () => { }
      });
    }
  }, []);

  const [opne, setOpne] = useState("0");
  const [opneMenu, setOpneMenu] = useState("0");

  const hendleOpne = (e) => {
    if (opne == "0") {
      setOpne("1");
      if (!localStorage.getItem("access_token")) {
        dispatch(productsAction.fatchPro({ product_ids: proId }));
        return () => { };
      }
    } else {
      setOpne("0");
    }
  };

  const hendleOpneMenu = (e) => {
    if (opneMenu == "0") {
      setOpneMenu("1");
    } else {
      setOpneMenu("0");
    }
  };

  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [anchor2, setAnchor2] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  MenuOutSideClick(anchorRef2, setAnchor2);

  const hendleSearch = (e) => {
    dispatch(productsAction.searchProduct({ keyword: e }));
    return () => { };
  };

  const blogids = localStorage.getItem("cart_product");
  const arrs = JSON.parse(blogids && blogids);

  var lists =
    commentsData &&
    commentsData.filter((x) => {
      return x?.cart_qty !== undefined;
    });

  const arr = localStorage.getItem("access_token")
    ? view_cart?.data && view_cart?.data
    : lists;

  const [itmeChange, setitmeChange] = useState([]);

  const deleteCartItmeOne = (e, productId) => {
    // deleteCartItme
    if (localStorage.getItem("access_token")) {
      const datas = new FormData();
      datas.append("cart_id", e);
      dispatch(productsAction.deleteCartItme(datas));
      return () => { };
    } else {
      var lists = arr.filter((x) => {
        return x.product_id != productId;
      });
      // dispatch(productsAction.getcartProduct({ data: lists, userid: get_user_profile?.id }))
      setitmeChange(lists);
      dispatch(productsAction.addcartItme(productId));
      dispatch(productsAction.fatchPro({ product_ids: proId }));
      localStorage.setItem("cart_product", JSON.stringify(lists));
      // return () => { }
    }
  };

  const cartTotalAmunt = localStorage.getItem("access_token")
    ? view_cart?.cart_total
    : totalAmount;


  const [menu, setMenu] = useState(false);


  return (
    <>
      {/* Title Section Start */}
      <div
        className=" container-fluid m-auto "
        style={{ backgroundColor: "#f743a6" }}
      >
        <div className=" text-center  col-sm-6 m-auto d-flex align-items-center">
          <p
            className="m-auto py-2"
            style={{
              fontFamily: "'Dancing Script', cursive",
              fontWeight: "100",
              fontSize: "15px",
              color: "white",
            }}
          >
            Shaadi Ho Ya Tyohaar kafi Pyar Aapke Dwaar

          </p>
        </div>
      </div>


      <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", borderBottom: "1px solid #ebebeb" }}>
        <Link className="navbar-brand " to={"/"}>
          <img
            src={Logo}
            alt=""
            width="150"
            height="150"
            className="ps-5 p-3"

          />
        </Link>

        <div
          className="navlinks"
          style={{ listStyle: "none" }}
        >


          <li className="nav-item col-sm-3" >
            <Link className=" NavLink" to={"/about"}
            >
              About
            </Link>

          </li>
          <li className="nav-item col-sm-4">
            <Link className=" NavLink" to={"/category"}
            >
              Category
            </Link>

          </li>
          <li className="nav-item col-sm-5">
            <Link className=" NavLink" to={"/customization"}
            >
              Customization
            </Link>

          </li>
          <li className="nav-item col-sm-4">
            <Link className=" NavLink" to={"/bulk-order"}
            >
              Bulk Order
            </Link>

          </li>
          <li className="nav-item col-sm-4">
            <Link className=" NavLink" to={"/faq"}
            >
              Faq
            </Link>

          </li>


        </div>

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>


          <a className="nav-link active d-flex align-items-center text-secondary">
            <SearchIcon className="i"></SearchIcon>
          </a>


          <div className="dropdown_search">
            <form>
              <input
                placeholder="Search product..."
                type="text"
                onChange={(e) => {
                  hendleSearch(e.target.value);
                }}
              />
              <button type="submit">
                <SearchIcon className="i"></SearchIcon>
              </button>
            </form>
            <div className="searchDrop">
              <ul>
                {search_product &&
                  search_product?.map((data, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => {
                          navigate(
                            "/product-details/" + data?.product_id
                          );
                        }}
                      >
                        {" "}
                        {data?.product_name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <Link
            to={"/products-cart"}
            className="nav-link active d-flex align-items-center text-secondary "
          >
            <ShoppingBagOutlinedIcon className="i"></ShoppingBagOutlinedIcon>

            {arr?.length > 0 && (
              <p className="cartItmeCount">{arr && arr?.length}</p>
            )}
          </Link>

          {localStorage.getItem("access_token") ? (
            <Link
              to={"/account"}
              className="nav-link active d-flex align-items-center text-secondary cartIcon"
            >
              {" "}
              <i class="fa fa-user i" aria-hidden="true"></i>
            </Link>
          ) : (
            <Link
              to={"/login"}
              className="nav-link active d-flex align-items-center text-secondary cartIcon"
            >
              {" "}
              <i class="fa fa-sign-in i" aria-hidden="true"></i>
            </Link>
          )}

        </div>


        <button
          className="navbar-toggler border-0 link-btn"
          type="button"
          onClick={() => setMenu(!menu)}
        >
          <MenuOpenIcon className="Menubar"></MenuOpenIcon>
        </button>



        <div
          className={"subMenu-nev" + (menu ? " active" : "")}
          style={{ listStyle: "none" }}
        >
          <div className=" " style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <div
              onClick={() => setMenu(!menu)}
            >
              close <i class="fa-solid fa-xmark"></i>
            </div>
          </div>


          <li className="nav-item col-sm-3" >
            <Link className=" NavLink" to={"/about"}
            >
              About
            </Link>

          </li>
          <li className="nav-item col-sm-4">
            <Link className=" NavLink" to={"/category"}
            >
              Category
            </Link>

          </li>
          <li className="nav-item col-sm-5">
            <Link className=" NavLink" to={"/customization"}
            >
              Customization
            </Link>

          </li>
          <li className="nav-item col-sm-4">
            <Link className=" NavLink" to={"/bulk-order"}
            >
              Bulk Order
            </Link>

          </li>
          <li className="nav-item col-sm-4">
            <Link className=" NavLink" to={"/faq"}
            >
              Faq
            </Link>

          </li>


        </div>

      </div>


    </>
  );
};

export default Header;
