import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { productsAction } from "../../Redux/common/action";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function Category() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const home_data = useSelector((state) =>
    state?.product?.home_data ? state?.product?.home_data : {}
  );
  const get_category = useSelector((state) =>
    state?.product?.get_category ? state?.product?.get_category : {}
  );

  console.log(get_category);

  useEffect(() => {
    dispatch(productsAction.gethomedata());
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(productsAction.getCategory());
    return () => {};
  }, []);

  return (
    <div>
      <Header />
      <div className="TitleText container text-center mt-4 ">
        <h3 className="p-3 Text fs-3 font-bold" style={{fontSize: '40px'}}>CATEGORY</h3>
      </div>{" "}
      <div className="container-fluid ">
        <div className="row ">
          {get_category &&
            get_category?.map((element, i) => {
              return (
                <>
                  <div
                    className="col-sm-4 p-1 onhPointer"
                    key={i}
                    onClick={(e) => {
                      navigate("/shop", { state: { id: element?.id } });
                    }}
                  >
                    <div className="image-container">
                      <img
                        src={element.icon}
                        alt="DisplayImage"
                        className="ImageCon1 m-auto mt-2 m-1"
                      />
                      <div className="text-container text-center  mb-3">
                      <h5 className="h4 mb-2 fs-3 font-black Text  mx-4" style={{color: "#000", backgroundColor: 'white', opacity: '0.5'}}>{element.title}</h5>
                        {/* <button
                          className="btn btn-secondary"
                          onClick={(e) => {
                            navigate("/shop", { state: { id: element?.id } });
                          }}
                        >
                          Explore
                        </button> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Category;
