import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { productsAction } from "../Redux/common/action";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
// import Footer from '../Component/Footer/Footer'
// import Header from '../Component/Header/Header'
// import { productsAction } from '../Redux/common/action';
// import ScrollToTop from '../ScrollToTop/ScrollToTop';

function ProductDetails() {
  const dispatch = useDispatch();
  const navigete = useNavigate();
  const id = useParams();
  const iddd = useLocation();

  // product_details

  // Outputs: 'https:'
  console.log(window.location.protocol);

  // Outputs: 'timmousk.com'
  console.log(window.location.hostname);

  // Outputs: '/blog/react-get-current-url/'
  console.log(window.location.pathname);

  const product = useSelector((state) =>
    state?.product?.product_details ? state?.product?.product_details : {}
  );

  useEffect(() => {
    const datas = new FormData();
    datas.append("product_id", id?.id);

    dispatch(productsAction.getProductDetails(datas));

    return () => {};
  }, [id?.id]);

  // const addtoItme = (e) => {
  //   // e.preventdefault()

  //   const datas = new FormData();

  //   datas.append("product_id", e);
  //   datas.append("qty", 1);
  //   dispatch(productsAction.addCartItme(datas));
  //   return () => {};
  // };

  const [itmeChange, setitmeChange] = useState([]);

  const [ctQ, setctQ] = useState(10);

  console.log(typeof ctQ);
  console.log(ctQ);

  const disbt = () => {
    const cs = ctQ - 1;

    if (10 > cs) {
      toast.error("minimum 10 product");
    } else {
      setctQ(cs);
    }
  };
  const inbt = () => {
    const cs = ctQ + 1;

    // if (10<cs) {
    //   toast.error("minimum 10 product")
    // } else {
    setctQ(cs);
    // }
  };

  const enterProductCount = (e) => {
    if (e) {
      const numAm = parseInt(e);

      // if (10 >= numAm) {
      //   toast.error("minimum 10 product select");
      // } else {
      setctQ(numAm);
    } else {
      setctQ(e);
    }
  };

  const addtoItme = (e) => {
    console.log(ctQ);
    console.log(10 >= ctQ);
    if (10 > ctQ) {
      toast.error("minimum 10 product select");
    } else {
      // setctQ(numAm);

      if (localStorage.getItem("access_token")) {
        const datas = new FormData();

        datas.append("product_id", e);
        datas.append("qty", ctQ);
        dispatch(productsAction.addCartItme(datas));
        setTimeout(() => {
          dispatch(productsAction.getviewcartdata());
        }, 1000);
        return () => {};
      } else {
        if (e !== undefined) {
          const blogIds = localStorage.getItem("cart_product");
          const blogIdss = blogIds !== null ? blogIds : "[]";
          const arr = JSON.parse(blogIdss);
          const alredyAdd = arr.indexOf(e);
          var lists = arr.filter((x) => {
            return x.product_id == e;
          });
          if (lists?.length == 0) {
            arr.push({ product_id: e, cart_qty: ctQ });
            setitmeChange(arr);
            localStorage.setItem("cart_product", JSON.stringify(arr));
            setTimeout(() => {
              const blogIds = localStorage.getItem("cart_product");
              const blogIdss = blogIds !== null ? blogIds : "[]";
              const arr = JSON.parse(blogIdss);
              const proId =
                arr &&
                arr?.map((data) => {
                  return data?.product_id;
                });
              toast.success("itme added  to cart");
              dispatch(productsAction.addcartItme(""));
              dispatch(productsAction.fatchPro({ product_ids: proId }));
              return () => {};
            }, 500);
          } else {
            const finalresult =
              arr &&
              arr?.map((data) => {
                return data;
              });

            setitmeChange(arr);
            toast.success("itme already add");
            localStorage.setItem("cart_product", JSON.stringify(finalresult));
          }
        }
      }
    }
  };

  // const [itmeChange, setitmeChange] = useState([]);

  // const increment = (e, price, data, productID) => {
  //   if (localStorage.getItem("access_token")) {
  //     const datas = new FormData();
  //     datas.append("cart_id", data);
  //     datas.append("price", price);
  //     const ucount = view_cart?.data?.filter((datass) => {
  //       return datass?.cart_id == data;
  //     });
  //     if (parseInt(ucount[0]?.cart_qty) < parseInt(e)) {
  //       dispatch(productsAction.incressQty(datas));
  //       return () => {};
  //     } else {
  //       dispatch(productsAction.decressQty(datas));
  //       return () => {};
  //     }
  //   } else {
  //     const ucount = arr?.filter((datass) => {
  //       return datass?.product_id == productID;
  //     });
  //     if (parseInt(ucount[0]?.cart_qty) < parseInt(e)) {
  //       // console.log("hjg");
  //       const finalresult =
  //         arr &&
  //         arr?.map((datasm) => {
  //           datasm.cart_qty =
  //             datasm?.product_id == productID
  //               ? datasm?.cart_qty + 1
  //               : datasm?.cart_qty;
  //           return datasm;
  //         });
  //       setitmeChange(finalresult);
  //       localStorage.setItem("cart_product", JSON.stringify(finalresult));
  //     } else {
  //       const finalresult =
  //         arr &&
  //         arr?.map((datasm) => {
  //           datasm.cart_qty =
  //             datasm?.product_id == productID
  //               ? datasm?.cart_qty - 1
  //               : datasm?.cart_qty;

  //           return datasm;
  //         });
  //       setitmeChange(finalresult);
  //       localStorage.setItem("cart_product", JSON.stringify(finalresult));
  //     }
  //   }
  // };

  return (
    <div>
      <ScrollToTop />
      <Header />
      <div className="product_details mv-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="product-details-tab">
                <div id="img-1" className="zoomWrapper  single-zoom">
                  <a href="#">
                    <img
                      id="zoom1"
                      src={product?.product_image}
                      data-zoom-image={product?.product_image}
                      alt="big-1"
                    />
                  </a>
                </div>

                {/* <div className="single-zoom-thumb">
                  <ul
                    className="s-tab-zoom owl-carousel single-product-active"
                    id="gallery_01"
                  >
                    <li>
                      <a
                        href="#"
                        className="elevatezoom-gallery active zoomGalleryActive"
                        data-update=""
                        data-image="assets/img/product/product2-big.jpg"
                        data-zoom-image="assets/img/product/product2-big.jpg"
                      >
                        <img
                          src="assets/img/product/product2.jpg"
                          alt="zo-th-1"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="elevatezoom-gallery active"
                        data-update=""
                        data-image="assets/img/product/product1-big.jpg"
                        data-zoom-image="assets/img/product/product1-big.jpg"
                      >
                        <img
                          src="assets/img/product/product13.jpg"
                          alt="zo-th-1"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="elevatezoom-gallery active"
                        data-update=""
                        data-image="assets/img/product/product3-big.jpg"
                        data-zoom-image="assets/img/product/product3-big.jpg"
                      >
                        <img
                          src="assets/img/product/product4.jpg"
                          alt="zo-th-1"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="elevatezoom-gallery active"
                        data-update=""
                        data-image="assets/img/product/product2-big.jpg"
                        data-zoom-image="assets/img/product/product2-big.jpg"
                      >
                        <img
                          src="assets/img/product/product2.jpg"
                          alt="zo-th-1"
                        />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="product_d_right">
                <h1>{product?.product_name}</h1>

                <div className="product_price">
                  {/* <span className="old_price">₹{product?.mrp}</span> */}
                  <span className="current_price">₹{product?.price}</span>
                </div>

                <div
                  className="product_desc"
                  dangerouslySetInnerHTML={{
                    __html:
                      product?.product_description?.length > 150
                        ? `${product?.product_description.substring(
                            0,
                            150
                          )}....`
                        : product?.product_description,
                  }}
                ></div>
                <div class="js-qty__wrapper mb-5 ms-3">
                  <input
                    type="text"
                    id="Quantity-5695950618786"
                    class="js-qty__num"
                    value={ctQ}
                    min="10"
                    aria-label="quantity"
                    pattern="[0-9]*"
                    name="quantity"
                    mm-min={"10"}
                    mm-stock-max="NaN"
                    step="1"
                    mm-step="1"
                    fdprocessedid="f05xsu"
                    onChange={(e) => {
                      enterProductCount(e.target.value);
                    }}
                  />
                  <button
                    type="button"
                    class="js-qty__adjust js-qty__adjust--minus"
                    aria-label="Reduce item quantity by one"
                    fdprocessedid="nqqfb9"
                    onClick={disbt}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon icon-minus"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#444"
                        d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                      ></path>
                    </svg>
                    <span class="icon__fallback-text" aria-hidden="true">
                      −
                    </span>
                  </button>

                  <button
                    type="button"
                    class="js-qty__adjust js-qty__adjust--plus"
                    aria-label="Increase item quantity by one"
                    fdprocessedid="mkiqbe"
                    onClick={inbt}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon icon-plus"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#444"
                        d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                      ></path>
                    </svg>
                    <span class="icon__fallback-text" aria-hidden="true">
                      +
                    </span>
                  </button>
                </div>
                <div className="product_variant quantity">
                  {/* <label>quantity</label>
                                <input min="1" max="100" value="1" type="number" /> */}
                  <button
                    className="button"
                    type="submit"
                    onClick={(e) => {
                      addtoItme(product?.product_id);
                    }}
                  >
                    add to cart
                  </button>
                </div>
                <div>
                  <p>Minimum 10 product order place</p>
                </div>

                <div className="product_variant  mt-5">
                  {/* <label>quantity</label>
                                <input min="1" max="100" value="1" type="number" /> */}
                  <button
                    className="button2"
                    type="submit"
                    onClick={(e) => {
                      navigete("/shipping");
                    }}
                  >
                    Shipping Policy
                  </button>
                </div>
                {/* <div className=" product_d_action">
                  <ul>
                    <li>
                      <a href="#" title="Add to wishlist">
                        + Add to Wishlist
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="product_meta">
                  <span>
                    Category: <a href="#">Clothing</a>
                  </span>
                </div>

                {/* </form> */}
                <div className="priduct_social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/kaafi.pyaar?mibextid=ZbWKwL"
                        target={"_blank"}
                        title="facebook"
                      >
                       <i class="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.instagram.com/kafipyar/?igshid=YmMyMTA2M2Y%3D"
                        target={"_blank"}
                        title="twitter"
                      >
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product_d_info">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="product_d_inner">
                <div className="product_info_button">
                  <ul className="nav" role="tablist">
                    <li>
                      <a
                        className="active"
                        data-bs-toggle="tab"
                        href="#info"
                        role="tab"
                        aria-controls="info"
                        aria-selected="false"
                      >
                        Description
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="info"
                    role="tabpanel"
                  >
                    <div
                      className="product_info_content"
                      dangerouslySetInnerHTML={{
                        __html: product?.product_description,
                      }}
                    ></div>
                  </div>

                  <div className="tab-pane fade" id="reviews" role="tabpanel">
                    <div className="reviews_wrapper">
                      <h2>1 review for Donec eu furniture</h2>
                      <div className="reviews_comment_box">
                        <div className="comment_thmb">
                          <img src="assets/img/blog/comment2.jpg" alt="" />
                        </div>
                        <div className="comment_text">
                          <div className="reviews_meta">
                            <div className="star_rating">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="ion-ios-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="ion-ios-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="ion-ios-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="ion-ios-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="ion-ios-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <p>
                              <strong>admin </strong>- September 12, 2018
                            </p>
                            <span>roadthemes</span>
                          </div>
                        </div>
                      </div>
                      <div className="comment_title">
                        <h2>Add a review </h2>
                        <p>
                          Your email address will not be published. Required
                          fields are marked{" "}
                        </p>
                      </div>
                      <div className="product_ratting mb-10">
                        <h3>Your rating</h3>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-star"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="product_review_form">
                        <form action="#">
                          <div className="row">
                            <div className="col-12">
                              <label for="review_comment">Your review </label>
                              <textarea
                                name="comment"
                                id="review_comment"
                              ></textarea>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <label for="author">Name</label>
                              <input id="author" type="text" />
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <label for="email">Email </label>
                              <input id="email" type="text" />
                            </div>
                          </div>
                          <button type="submit">Submit</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ProductDetails;
