import axios from 'axios';

const API_KEY = 'ca5a2c0849e2da810fda2704854980e2-777a617d-d1c45e32';
const DOMAIN = 'sandbox6cc5aa23ce094a95b0ea8cd53c0a3aae.mailgun.org';
const BASE_URL = `https://api.mailgun.net/v3/${DOMAIN}/messages`;

const sendEmail = async ({from, subject, html}) => {
    const data = new URLSearchParams();
    data.append('from', from);
    data.append('to', 'kafipyar.customercare@gmail.com');
    data.append('subject', subject);
    data.append('html', html);

    try {
        const response = await axios.post(BASE_URL, data, {
            auth: {
                username: 'api',
                password: API_KEY
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export default sendEmail;
