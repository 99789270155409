import React from "react";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import axios from "axios";
import Slider from "react-slick";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";

import SliderImage from "../img/j.jpg";
import { useState, useEffect } from "react";
import "../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { productsAction } from "../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import { useNavigate } from "react-router-dom";
import Highlight from "./Highlight";
import Faq from "./Faq";
import Testimonial from "./Testimonial";
import Cards from "./Cards";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const home_data = useSelector((state) =>
    state?.product?.home_data ? state?.product?.home_data : {}
  );

  useEffect(() => {
    dispatch(productsAction.gethomedata());
    return () => { };
  }, []);

  // useEffect(() => {
  //   function makeGetRequest(path) {
  //     axios.get(path).then(
  //       (response) => {
  //         var result = response.data;
  //         // console.log(result);
  //         setState(result.data.featured_product);
  //         setData(result.data.catrgory);
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  //   }
  //   makeGetRequest("http://jwellerydemo.softwarejaipur.com/rest/v3/home");
  // }, []);

  const [state, setState] = useState("");
  const [data, setData] = useState("");
  const [ModalData, setModalData] = useState("");

  const [ctQ, setctQ] = useState(10);

  const ModalBox = (FetchedData) => {
    setModalData(FetchedData);
    setctQ(10);
  };

  const blogIds = localStorage.getItem("cart_product");
  const blogIdss = blogIds !== null ? blogIds : "[]";
  const arr = JSON.parse(blogIdss);

  const proId =
    arr &&
    arr?.map((data) => {
      return data?.product_id;
    });

  const disbt = () => {
    const cs = ctQ - 1;

    if (10 > cs) {
      toast.error("minimum 10 product");
    } else {
      setctQ(cs);
    }
  };
  const inbt = () => {
    const cs = ctQ + 1;

    // if (10<cs) {
    //   toast.error("minimum 10 product")
    // } else {
    setctQ(cs);
    // }
  };

  const enterProductCount = (e) => {
    // console.log(e);

    if (e) {
      const numAm = parseInt(e);

      // if (10 >= numAm) {
      //   toast.error("minimum 10 product select");
      // } else {
      setctQ(numAm);
    } else {
      setctQ(e);
    }

    // }
  };
  const addtoItme = (e) => {
    if (10 > ctQ) {
      toast.error("minimum 10 product select");
    } else {
      if (localStorage.getItem("access_token")) {
        const datas = new FormData();

        datas.append("product_id", e);
        datas.append("qty", ctQ);
        dispatch(productsAction.addCartItme(datas));
        setTimeout(() => {
          dispatch(productsAction.getviewcartdata());
        }, 1000);
        return () => { };
      } else {
        if (e !== undefined) {
          const alredyAdd = arr.indexOf(e);
          var lists = arr.filter((x) => {
            return x.product_id == e;
          });

          if (lists?.length == 0) {
            arr.push({ product_id: e, cart_qty: ctQ });
            localStorage.setItem("cart_product", JSON.stringify(arr));

            setTimeout(() => {
              const blogIds = localStorage.getItem("cart_product");
              const blogIdss = blogIds !== null ? blogIds : "[]";
              const arr = JSON.parse(blogIdss);
              const proId =
                arr &&
                arr?.map((data) => {
                  return data?.product_id;
                });
              toast.success("itme added  to cart");
              dispatch(productsAction.addcartItme(""));
              dispatch(productsAction.fatchPro({ product_ids: proId }));
              return () => { };
            }, 500);
          } else {
            const finalresult =
              arr &&
              arr?.map((data) => {
                return data;
              });
            toast.success("itme already add");
            localStorage.setItem("cart_product", JSON.stringify(finalresult));
          }
        }
      }
    }
  };

  return (
    <>
      <ScrollToTop />
      <Header />

      {/* Video Presendation Start */}
      <div className="container-fluid">
        {/* <div className="VideoContainer"> */}
          <video
            autoPlay
            playsInline
            muted
            className="video"
            loop
            src="/Teaser.mp4"
          />

        {/* </div> */}
      </div>
      {/* Video Presendation End */}


      {/* Middle Line Text Here */}
      <div className="container-fluid " style={{ height: "auto" }}>
        <div className="col-sm-4  m-auto p-3 text-center">
          <span>
            <img
              src="https://cdn.shopify.com/s/files/1/0466/5763/4466/files/RPG_Website_star_50x50@2x.png?v=1602313331"
              className="mt-3 middleIconImage"
            />
          </span>
          <h3 className="p-3 mt-3 text-center MiddleLineText"  >
            Kafi pyar is a social enterprise empowering women and local
            artisans. We hail from Jaipur but offer our love and service all
            across India. Offering wide range of customizable festive giveaway
            options, gifting happiness in sustainable ways. Basically we are a
            small parivaar, aapki khushiyon mein shamil har tyohaar.
            {/* Our potlis are stuffed with memories, Of twinkling lights that get
            untangled before big days, Of family game nights spent playing
            charades. */}
          </h3>
        </div>
      </div>
      {/* Middle Line Text Here */}
      {/* Slick Buying Cards */}
      {/* Slick Buying Cards */}
      {/* Cards Section Starts Here */}
      <div className="TitleText container text-center mt-4 ">
        <h3 className="p-3" style={{ fontWeight: 'bold', fontSize: '40px' }}>CATEGORY</h3>
      </div>
      <div className="container-fluid ">
        <div className="row ">
          {home_data?.catrgory &&
            home_data?.catrgory?.map((element, i) => {
              return (
                <>
                  <div
                    className="col-sm-4 p-1 onhPointer"
                    key={i}
                    onClick={(e) => {
                      navigate("/shop", { state: { id: element?.id } });
                    }}
                  >
                    <div className="image-container">
                      <img
                        src={element.icon}
                        alt="DisplayImage"
                        className="ImageCon1 m-auto mt-2 m-1"
                      />
                      <div className="text-container text-center  mb-3">
                        <h5 className="h4 mb-2 fs-3 font-black  mx-4 Text"  style={{ color: "#000", backgroundColor: 'white', opacity: '0.5' }}>{element.title}</h5>
                        {/* <button
                          className="btn btn-secondary"
                          onClick={(e) => {
                            navigate("/shop", { state: { id: element?.id } });
                          }}
                        >
                          Explore
                        </button> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
      {/* Cards Section Ends Here */}
      {/* Carousel Buying Card Start */}
      {/* <div className="TitleText container text-center mt-4 ">
        <h3 className="p-3">FEATURE PRODUCTS</h3>
        
      </div>
      <div className="container-fluid text-center mt-5">
        <div className="row mt-4 row-cols-2  row-cols-md-4">
          {home_data?.featured_product &&
            home_data?.featured_product?.map((element, i) => {
              return (
               <Cards key={i} element={element} />
              );
            })}
        </div>
      </div> */}
      {/* Carousel Buying Card End */}

      {/* Carousel Starts Here */}
      <div
        id="carouselExampleDark"
        className="carousel carousel-light slide carousel-fade"
        data-bs-ride="carousel"
        style={{ marginTop: "150px" }}
      >
        <div className="carousel-inner">
          <div className="carousel-item carouselItem2">
            <div
              className="  col-sm-4 ms-auto text-light mainText   "
              style={{
                textAlign: "right",
                paddingRight: "100px",
              }}
            >

            </div>
          </div>
          <div className="carousel-item carouselItem3">
            <div
              className="  col-sm-4 ms-auto text-light mainText  "
              style={{
                textAlign: "right",
                paddingRight: "100px",
              }}
            >
              {/* <h1 className="pt-3 sliderText">
                Limited Time
                <br /> Offerings !
              </h1>
              <p className="my-3 middleText">
                Our Luxury Trousseue Bags
                <br /> Are Now At 50% Off
              </p>
              <button className=" btn btn-light sliderButton">Shop Now</button> */}
            </div>
          </div>
          <div className="carousel-item active carouselItem1">
            <div
              className="  col-sm-4 ms-auto text-light mainText   "
              style={{
                textAlign: "right",
                paddingRight: "100px",
              }}
            >
              {/* <h1 className="pt-3 sliderText">
                Summer Surprise
                <br /> Sale
              </h1>
              <p className="my-3 middleText">Upto 50% Off</p>
              <button className=" btn btn-light sliderButton">Shop Now</button> */}
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        // style={{ display: "none" }}
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        // style={{ display: "none" }}
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      {/* Carousel Ends Here */}
      <Highlight />
      <Testimonial />
      {/* <Faq /> */}
      {/* Hero Image Section Starts */}
      <div className="mb-3">
        <div className="HeroBannerImage">
          <div className="HeroBannerText col-sm-3  p-4 ms-5 bg-white">
            {/* <p>A CELEBRATION OF TRADITIONS</p> */}
            <h3 className="Text fs-4">Ananya Rawat, founder of Kafi Pyar.</h3>
            <h5>
              Born and brought up in a close-knit family in Jaipur, she
              cultivated the habit of celebrating even the smallest of occasions
              in the family with pomp and excitement. Jaipur's markets, its
              local arts and crafts heritage and its rich culture further
              enriched her experiences and showcased everything that could be
              achieved when planning celebrations.{" "}
              {/* Inspired by the women of
              Jaipur, who pour their heart and soul into creating beautiful
              pieces for people all over the world, she wanted to channel her
              vision of empowering women and celebrating life at every moment
              into something that would make Rajasthani cultural pieces more
              accessible to everyone in India. This made her to come up with
              Kafi Pyar, a social enterprise which offers plethora of products,
              crafted sustainably by local women. Through Kafi Pyar, Ananya sees
              her vision in action, and rejoices by catering to your
              larger-than-life celebrations. */}
            </h5>
            {/* <button className="btn btn-dark">Read More</button> */}
          </div>
        </div>
      </div>
      {/* Hero Image Section Ends */}
      {/* Memorial Cards Start */}
      <div className="MemorialCards container-fluid  mt-4 mb-3">
        <div className="MemorialCardText text- col-sm-6  m-auto text-center mb-3">
          {/* <h2 className="p-3"> GREAT DEAL PRODUCTS</h2> */}
          {/* <button className="mb-2 btn btn-secondary">VIEW ALL</button> */}
        </div>

        <div className="MemorialCardDetails container-fluid mt-4 ">
          <div className="row m-auto">
            {home_data?.deal_product &&
              home_data?.deal_product?.map((data, i) => {
                return (
                  <div className="col-sm-4  " key={i}>
                    <div className="MemorialCard1  m-auto text-center">
                      <div className="col-12  CardImageContainer">
                        <img
                          src={data?.product_image}
                          alt="MemorialCardImage"
                          className=" MemorialCardImage1  "
                        />
                      </div>
                      <div className="MemorialCardText1 col-6  pb-3">
                        {/* <p>Oct 12, 2020</p> */}
                        <p>
                          <span
                            style={{
                              textDecoration: "line-through",
                              color: "black",
                            }}
                          >
                            Rs.{data?.mrp}
                          </span>{" "}
                          From Rs. {data?.price}
                          <span className="text-danger">
                            Save {parseInt(data.mrp) - parseInt(data?.price)}
                          </span>
                        </p>
                        <h3>{data?.product_name}</h3>
                        {/* <h5>by Anika Dhawan</h5> */}
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div className="col-sm-4  ">
              <div className="MemorialCard1  m-auto text-center">
                <div className="col-12  CardImageContainer">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0466/5763/4466/articles/5FD4CD7F-BAB2-4821-92D7-01877FE04B82_400x.jpg?v=1601975971"
                    alt="MemorialCardImage"
                    className=" MemorialCardImage2   "
                  />
                </div>
                <div className="MemorialCardText1 col-6  pb-3">
                  <p>Oct 12, 2020</p>
                  <h3>Sunday Family Menu</h3>
                  <h5>by Anika Dhawan</h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4  ">
              <div className="MemorialCard1  m-auto text-center">
                <div className="col-12  CardImageContainer card3">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0466/5763/4466/articles/Wedding_4bc9494f-1047-43b4-9930-e4733959848e_540x.jpg?v=1602167152"
                    alt="MemorialCardImage"
                    className=" MemorialCardImage1  "
                  />
                </div>
                <div className="MemorialCardText1 col-6  pb-3">
                  <p>Oct 12, 2020</p>
                  <h3>A Wedding Closest To My Heart</h3>
                  <h5>by Anika Dhawan</h5>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Memorial Cards End */}\{/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default Home;
