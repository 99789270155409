import React from "react";
import "../App.css";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import  sendEmail  from "./email";

const All = () => {
  const [form, setForm] = React.useState({
    email: "",
    message: "",
    number: "",
    name: ""
  })
  const sendMail = async () => {

    if(!form?.email || !form?.message){
      if(!form?.email){
       alert("Email Required")
      }
  
      if(!form?.message){
        alert("Message Required")
      }

      return
    } 

    try {
      const res = await sendEmail({
        from: form?.email,
        subject: "Customization Order",
        html: `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Email Message</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.5;
            margin: 0;
            padding: 20px;
            background-color: #f4f4f4;
        }
        .container {
            background-color: #fff;
            padding: 20px;
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
        .footer {
            margin-top: 20px;
            font-size: 0.9em;
            color: #555;
        }
    </style>
</head>
<body>
    <div class="container">
      
        <p><strong>Message:</strong> ${form?.message}</p>
        <p><strong>Name:</strong> ${form?.name}</p>
        <p><strong>Phone:</strong> ${form?.number}</p>
    </div>
    <div class="footer">
        <p>Thank you for your inquiry!</p>
    </div>
</body>
</html>
`,
      })
      console.log(res);
      if(res.message){
       
        setForm({
          email: "",
          message: "",
          number: "",
          name: ""
        })
        // alert(res.message)
      }
    } catch (error) {
      console.log(error);
      alert(error)

    }
  }

  return (
    <>
      <ScrollToTop />
      <Header />

      {/* Hero Banner Section Start */}
      <div className="container-fluid ">
        <div className="col-sm-5  m-auto text-center p-5 AllSection" >
          <h2>Custom Order</h2>
          <h5 className="mt-3">
            Kafi pyar provides service of customization. We understand every
            function is unique, every theme is new and every requirement is
            valueable. So, here we are catering to all your needs and crafting
            products according to your requirements by keeping your budget in
            mind.
          </h5>
          <h5 >
            We customize available range of products on basis of size, color
            theme, fabric and budget
          </h5>
          <h5 >Contact No. +91 7062199421</h5>
        </div>

        <div className=" container-fluid col-sm-8 ">
          <div className=" m-auto text-center mb-3 AllSection">
            <img
              src="/IMG_4891.jpg"
              alt="HeroImageSection"
              className="h-80 w-80"
            />
            <h4 className="pt-5">Size</h4>
            <h5 className="">
              We have a wide range of products which are made in three
              particular sizes (5*7, 7*9 & 8*10 inches) If you have any
              particular need, we can craft the available range in the required
              size.
            </h5>


            <h4 className="pt-5">Color</h4>
            <h5 >
              We craft our products majorly in 7-8 shades both dark and pastel
              but as Theme weddings and occasions are highlights of this new
              celebration era. Reach out to us to craft the available range in
              any particular color required.
            </h5>
            <h4 className="pt-5">Fabric </h4>
            <h5 >
              We deal in wide range of fabrics (Dupin, Banarasi, Satin, Organza,
              Cotton, Kota doria, Silk). If you have any particular fabric in
              mind, let us know we will craft the available range in the
              required fabric.
            </h5>

            <h4 className="pt-5">Personalized products</h4>
            <h5 >
              We craft personalized products by printing your name/ hashtag/
              illustration on the products. This gives a unique touch to your
              product.
            </h5>

            <h4 className="pt-5">Curate a Hamper/ Gift</h4>
            <h5 >
              Give us an incite of your theme, requirement and budget. We will
              come up with options suitable to you.
            </h5>

            <h4 className="pt-5">Specific Product</h4>
            <h5 >
              You have a specific product in mind, share it with us in form of
              an image and we will craft it for you.
            </h5>
            <h5 >
              Minimum order quantity for customization will be 50 pcs.
            </h5>
          </div>
        </div>
      </div>
      {/* Hero Banner Section End */}

      {/* Contact Form Starts */}
      <div className="col-sm-8 text-center m-auto bg-successs mb-5">
        <div className="input-group mb-3">
          <input
            type="name"
            className="form-control m-3"
            placeholder="Username"
            aria-label="Username"
            value={form?.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
          />
          <input
            type="email"
            className="form-control m-3"
            placeholder="Email"
            aria-label="Server"
            value={form?.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        </div>
        <div className="input-group p-3 mb-3">
          <input
            type="tel"
            className="form-control"
            placeholder="Phone Number"
            aria-label="Username"
            value={form?.number}
            onChange={(e) => setForm({ ...form, number: e.target.value })}
          />
        </div>
        <div className="input-group">
          <textarea
            className="form-control m-3 "
            aria-label="With textarea"
            placeholder="Message"
            value={form?.message}
            onChange={(e) => setForm({ ...form, message: e.target.value })}
          ></textarea>
        </div>

        <button onClick={sendMail} className="btn btn-dark ContactButton">SEND</button>


      </div>
      {/* Contact Form Ends */}

      {/* Memorial Cards Start */}
      {/* <div className="MemorialCards container-fluid  mt-4 mb-3">
        <div className="MemorialCardText text- col-sm-6  m-auto text-center mb-3">
          <h2 className="p-3">MEMOIRS, MARRIAGES & MITHAI</h2>
          <button className="mb-2 btn btn-secondary">VIEW ALL</button>
        </div>

        <div className="MemorialCardDetails container-fluid mt-4 ">
          <div className="row m-auto">
            <div className="col-sm-4  ">
              <div className="MemorialCard1  m-auto text-center">
                <div className="col-12  CardImageContainer">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0466/5763/4466/articles/Inside-Rani_09d5fd37-f588-4f4e-af22-093475c8e231_1080x.jpg?v=1602171215%201080w%20607h"
                    alt="MemorialCardImage"
                    className=" MemorialCardImage1  "
                  />
                </div>
                <div className="MemorialCardText1 col-6  pb-3">
                  <p>Oct 12, 2020</p>
                  <h3>Inside Rani Pink Gifts</h3>
                  <h5>by Anika Dhawan</h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4  ">
              <div className="MemorialCard1  m-auto text-center">
                <div className="col-12  CardImageContainer">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0466/5763/4466/articles/5FD4CD7F-BAB2-4821-92D7-01877FE04B82_400x.jpg?v=1601975971"
                    alt="MemorialCardImage"
                    className=" MemorialCardImage2   "
                  />
                </div>
                <div className="MemorialCardText1 col-6  pb-3">
                  <p>Oct 12, 2020</p>
                  <h3>Sunday Family Menu</h3>
                  <h5>by Anika Dhawan</h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4  ">
              <div className="MemorialCard1  m-auto text-center">
                <div className="col-12  CardImageContainer card3">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0466/5763/4466/articles/Wedding_4bc9494f-1047-43b4-9930-e4733959848e_540x.jpg?v=1602167152"
                    alt="MemorialCardImage"
                    className=" MemorialCardImage1  "
                  />
                </div>
                <div className="MemorialCardText1 col-6  pb-3">
                  <p>Oct 12, 2020</p>
                  <h3>A Wedding Closest To My Heart</h3>
                  <h5>by Anika Dhawan</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Memorial Cards End */}

      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
    </>
  );
};

export default All;
