import React from "react";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import axios from "axios";
import Slider from "react-slick";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";

import SliderImage from "../img/j.jpg";
import { useState, useEffect } from "react";
import "../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { productsAction } from "../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import { useNavigate } from "react-router-dom";
import Highlight from "./Highlight";
import Faq from "./Faq";
import Testimonial from "./Testimonial";



function Cards({ element }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(productsAction.gethomedata());
        return () => { };
    }, []);

    // useEffect(() => {
    //   function makeGetRequest(path) {
    //     axios.get(path).then(
    //       (response) => {
    //         var result = response.data;
    //         // console.log(result);
    //         setState(result.data.featured_product);
    //         setData(result.data.catrgory);
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     );
    //   }
    //   makeGetRequest("http://jwellerydemo.softwarejaipur.com/rest/v3/home");
    // }, []);

    const [ModalData, setModalData] = useState(element);

    const [ctQ, setctQ] = useState(10);

    const ModalBox = () => {
        setctQ(10);
    };

    const blogIds = localStorage.getItem("cart_product");
    const blogIdss = blogIds !== null ? blogIds : "[]";
    const arr = JSON.parse(blogIdss);

    const proId =
        arr &&
        arr?.map((data) => {
            return data?.product_id;
        });

    const disbt = () => {
        const cs = ctQ - 1;

        if (10 > cs) {
            toast.error("minimum 10 product");
        } else {
            setctQ(cs);
        }
    };
    const inbt = () => {
        const cs = ctQ + 1;

        // if (10<cs) {
        //   toast.error("minimum 10 product")
        // } else {
        setctQ(cs);
        // }
    };

    const enterProductCount = (e) => {
        // console.log(e);

        if (e) {
            const numAm = parseInt(e);

            // if (10 >= numAm) {
            //   toast.error("minimum 10 product select");
            // } else {
            setctQ(numAm);
        } else {
            setctQ(e);
        }

        // }
    };
    const addtoItme = (e) => {
        if (10 > ctQ) {
            toast.error("minimum 10 product select");
        } else {
            if (localStorage.getItem("access_token")) {
                const datas = new FormData();

                datas.append("product_id", e);
                datas.append("qty", ctQ);
                dispatch(productsAction.addCartItme(datas));
                setTimeout(() => {
                    dispatch(productsAction.getviewcartdata());
                }, 1000);
                return () => { };
            } else {
                if (e !== undefined) {
                    const alredyAdd = arr.indexOf(e);
                    var lists = arr.filter((x) => {
                        return x.product_id == e;
                    });

                    if (lists?.length == 0) {
                        arr.push({ product_id: e, cart_qty: ctQ });
                        localStorage.setItem("cart_product", JSON.stringify(arr));

                        setTimeout(() => {
                            const blogIds = localStorage.getItem("cart_product");
                            const blogIdss = blogIds !== null ? blogIds : "[]";
                            const arr = JSON.parse(blogIdss);
                            const proId =
                                arr &&
                                arr?.map((data) => {
                                    return data?.product_id;
                                });
                            toast.success("itme added  to cart");
                            dispatch(productsAction.addcartItme(""));
                            dispatch(productsAction.fatchPro({ product_ids: proId }));
                            return () => { };
                        }, 500);
                    } else {
                        const finalresult =
                            arr &&
                            arr?.map((data) => {
                                return data;
                            });
                        toast.success("itme already add");
                        localStorage.setItem("cart_product", JSON.stringify(finalresult));
                    }
                }
            }
        }
    };

    const [hower, sethower] = useState(false);

    const [mobile_View, setmobile_View] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setmobile_View(true);
            } else {
                setmobile_View(false);
            }
        };
        console.log(mobile_View);
    
        // Set initial value based on current window width
        handleResize();
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <div
                className="col contained"
                style={{ position: "relative" }}
                onMouseEnter={() => sethower(true)}
                onMouseLeave={() => sethower(false)}
            >


       
                    <img
                        src={element.product_image}
                        alt="BuyingCardImage"
                        className={`BuyingImage ${hower && "imgActive"}`}
                        onClick={(e) => {
                            navigate("/product-details/" + element?.product_id);
                        }}
                    />

                    {
                     (hower || mobile_View) &&
                        <div className={`${!mobile_View ? 'viewIcon' : 'ViewIconMobile'}`}  >
                            <a
                                href="#"
                                // target={"_blank"}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={(e) => {
                                    ModalBox(element);
                                }}
                            >
                                <i class="fa fa-eye"></i>
                                {/* <InstagramIcon className=""></InstagramIcon> */}
                            </a>
                        </div>
                    }








                <div
                    className="CardText "
                    onClick={(e) => {
                        navigate("/product-details/" + element?.product_id);
                    }}
                >
                    <h5 className=" pt-3">{element.product_name}</h5>
                    <p>
                        <span
                            style={{
                                textDecoration: "line-through",
                                color: "black",
                            }}
                        >
                            Rs.{element?.mrp}
                        </span>{" "}
                        From Rs. {element?.price}
                        <span className="text-danger">
                            Save{" "}
                            {parseInt(element.mrp) - parseInt(element?.price)}
                        </span>
                    </p>
                </div>
            </div>


            {/* Modal Box  Start */}
            <div
                className="modal fade "
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg ">
                    <div className="modal-content ">
                        <div className="modal-header border-0">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body  col-12">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-4 ">
                                        <div className=" bg-dark">
                                            <img
                                                src={ModalData.product_image}
                                                className="h-50 w-100 "
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-8  ModalText">
                                        <h5> {ModalData.product_name} </h5>
                                        <h6>
                                            ₹{ModalData?.price} <s>₹{ModalData?.mrp}</s>
                                        </h6>

                                        <div class="js-qty__wrapper  ms-3">
                                            <input
                                                type="text"
                                                id="Quantity-5695950618786"
                                                class="js-qty__num"
                                                value={ctQ}
                                                min="10"
                                                aria-label="quantity"
                                                pattern="[0-9]*"
                                                name="quantity"
                                                mm-min={"10"}
                                                mm-stock-max="NaN"
                                                step="1"
                                                mm-step="1"
                                                fdprocessedid="f05xsu"
                                                onChange={(e) => {
                                                    enterProductCount(e.target.value);
                                                }}
                                            />
                                            <button
                                                type="button"
                                                class="js-qty__adjust js-qty__adjust--minus"
                                                aria-label="Reduce item quantity by one"
                                                fdprocessedid="nqqfb9"
                                                onClick={disbt}
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    role="presentation"
                                                    class="icon icon-minus"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        fill="#444"
                                                        d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                                                    ></path>
                                                </svg>
                                                <span class="icon__fallback-text" aria-hidden="true">
                                                    −
                                                </span>
                                            </button>

                                            <button
                                                type="button"
                                                class="js-qty__adjust js-qty__adjust--plus"
                                                aria-label="Increase item quantity by one"
                                                fdprocessedid="mkiqbe"
                                                onClick={inbt}
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    role="presentation"
                                                    class="icon icon-plus"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        fill="#444"
                                                        d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                                                    ></path>
                                                </svg>
                                                <span class="icon__fallback-text" aria-hidden="true">
                                                    +
                                                </span>
                                            </button>
                                        </div>
                                        <h6>See All Features</h6>
                                        <button className="btn btn-dark m-3">
                                            <a
                                                className="NavLink text-light"
                                                data-bs-dismiss={ctQ > 9 ? "modal" : ""}
                                                onClick={(e) => {
                                                    addtoItme(ModalData?.product_id);
                                                }}
                                            >
                                                ADD TO CART
                                            </a>
                                        </button>

                                        <div className="mt-2 mb-2 ModalTextBox p-1">
                                            <div
                                                className="modal_description mb-15"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        ModalData?.product_description?.length > 150
                                                            ? `${ModalData?.product_description.substring(
                                                                0,
                                                                150
                                                            )}....`
                                                            : ModalData?.product_description,
                                                }}
                                            ></div>
                                        </div>

                                        <div className="SocialMedia">
                                            <h6>Share Our product</h6>
                                            <div className=" SocialMediaIcons d-flex ms-3">
                                                <div className="col-1">
                                                    <a
                                                        href="https://www.facebook.com/kaafi.pyaar?mibextid=ZbWKwL"
                                                        target={"_blank"}
                                                    >
                                                        <FacebookIcon className=""></FacebookIcon>
                                                    </a>
                                                </div>
                                                <div className="col-1">
                                                    <a
                                                        href="https://www.instagram.com/kafipyar/?igshid=YmMyMTA2M2Y%3D"
                                                        target={"_blank"}
                                                    >
                                                        <InstagramIcon className=""></InstagramIcon>
                                                    </a>
                                                </div>
                                                <div className="col-1">
                                                    <a
                                                        href="https://in.pinterest.com/kafipyar/?invite_code=bfb4217e282747ec81cd88b23ea9c356&sender=1041809463708864739"
                                                        target={"_blank"}
                                                    >
                                                        <PinterestIcon className=""></PinterestIcon>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Box Ends */}

        </div>
    )
}

export default Cards