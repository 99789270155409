import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
const ShippingPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <Header />

      <div className="container AboutSection p-5">
        <h4>Shipping policy</h4>
        <p>
          Additional shipping charges are applicable on all our products and
          will be calculated based upon the location of delivery, quantity,
          weight and volume of the package..
        </p>
        {/* <h6>Why should I choose Partmech?</h6> */}
        <p>
          We can ship products almost anywhere in India and across the globe
          other than a few locations, which our logistic partners do not serve.
        </p>
        <p>
          When you place an order, we will estimate delivery dates based upon
          the availability of your item(s) and your shipment's destination. The
          estimated delivery dates may however differ from actual delivery dates
          and our failure to comply with the estimated delivery date will not
          give rise to any cause of action against us.
        </p>
        <p>
          We usually get your shipment delivered within 6-10 business days of
          your placing the order on our website depending on the location. We
          provide both the types of delivery i.e., Normal Delivery and Express
          Delivery.
        </p>
        <p>
          If there is any further delay in shipping the products, someone from
          our customer support will contact you.
        </p>
        <p>
          Our courier partner captures non-delivered items information in real
          time and tries to deliver next day.
        </p>
        <h4>Domestic / International Shipping:</h4>
        <p>
          Shipping charges will be calculated based on the location and weight
          of the products. Even though we try our best to ensure on-time
          delivery, there are certain conditions under which the delivery may
          get delayed:
        </p>
        <ul>
          <li>Incorrect shipping address</li>
          <li>Incorrect PIN code</li>
          <li>Nobody available at shipping address</li>
          <li>Extreme weather conditions</li>
          <li>Other Force Majeur</li>
          <li>
            Unforeseen circumstances beyond the Control of our logistics
            partners
          </li>
        </ul>
        <p className="mt-3">
          We send you a confirmation email / SMS once the order is shipped and
          another email when the order is delivered.
        </p>
        <p>
          Our Courier partner will deliver the product to you at the address
          provided by you.
        </p>

        <p>
          You may collect it after signing the acknowledgement with Contact No.
          It is mandatory to give Telephone no. or Mobile no. of the person who
          receives the package.
        </p>
        <p>Shipping charges are non-refundable.</p>
        <p>For any further enquiry please contact us at</p>

        <a href="mailto:kafipyar.customercare@gmail.com">
          kafipyar.customercare@gmail.com
        </a>
        {/* <p>
          Our distinctive business model enables us to provide affordable car
          services. We achieve savings on labour costs, centralized bulk
          procurement of spare parts, no real-estate overheads, and adept
          operational excellence, which are passed on straight to You- the
          Customer
        </p> */}
      </div>

      <Footer />
    </>
  );
};

export default ShippingPolicy;
