import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
const TermsCondition = () => {
  return (
    <>
      <ScrollToTop />
      <Header />

      <div className="container AboutSection p-5">
        <p>
          These terms constitute a legally binding and enforceable agreement
          between PartMech (“Company/we/us/our”) and its Users (“User/you/your”)
          and govern your use of the Platform to browse and/or avail the
          Services displayed by us on the Platform. By accessing and using our
          Services, you agree to be bound by these terms, establishing a
          contractual relationship between you and the Company. In case you do
          not agree to the terms, you may not use or access or stop your use or
          access of our Services. These Terms expressly supersede prior written
          agreements with you. The Company requests the User to carefully go
          through these terms & conditions (“Terms and Conditions” or “Terms”)
          prior to accessing the Platform or availing Services (as defined
          below) using the Platform.
        </p>

        <p>
          The Company reserves the right, in its sole discretion, to change,
          modify or amend the Terms for complying with the legal or regulatory
          framework and for other legitimate business purposes, at any time, and
          the Company will post the amended Terms at the domain of
          www.partmecht.com. It is your responsibility to review the Terms for
          any changes and you are encouraged to check the Terms frequently. The
          Company shall not be under an obligation to notify Users of any
          changes to the Terms and Conditions. If you continue to use the
          Platform, Content (as defined below) and/or Services after the updated
          Terms and Conditions have been published, it shall be deemed that you
          have read and understood and accept the updated Terms and Conditions.
          Any reference to Terms and Conditions, made herein shall refer to the
          latest version of the Terms and Conditions.
        </p>

        <p>
          No information provided on the Platform shall be considered a
          substitute for your independent investigation. These Terms are
          collectively an electronic record in terms of the Indian Contract Act,
          1872; the Information Technology Act, 2000, the rules made thereunder;
          and the amended provisions pertaining to the electronic records in
          various other
        </p>

        <h6 className="pt-3 pb-3">SERVICES</h6>

        <p>
          These terms constitute a legally binding and enforceable agreement
          between PartMech (“Company/we/us/our”) and its Users (“User/you/your”)
          and govern your use of the Platform to browse and/or avail the
          Services displayed by us on the Platform. By accessing and using our
          Services, you agree to be bound by these terms, establishing a
          contractual relationship between you and the Company. In case you do
          not agree to the terms, you may not use or access or stop your use or
          access of our Services. These Terms expressly supersede prior written
          agreements with you. The Company requests the User to carefully go
          through these terms & conditions (“Terms and Conditions” or “Terms”)
          prior to accessing the Platform or availing Services (as defined
          below) using the Platform.
        </p>

        <p>
          The Company reserves the right, in its sole discretion, to change,
          modify or amend the Terms for complying with the legal or regulatory
          framework and for other legitimate business purposes, at any time, and
          the Company will post the amended Terms at the domain of
          www.partmecht.com. It is your responsibility to review the Terms for
          any changes and you are encouraged to check the Terms frequently. The
          Company shall not be under an obligation to notify Users of any
          changes to the Terms and Conditions. If you continue to use the
          Platform, Content (as defined below) and/or Services after the updated
          Terms and Conditions have been published, it shall be deemed that you
          have read and understood and accept the updated Terms and Conditions.
          Any reference to Terms and Conditions, made herein shall refer to the
          latest version of the Terms and Conditions.
        </p>

        <p>
          No information provided on the Platform shall be considered a
          substitute for your independent investigation. These Terms are
          collectively an electronic record in terms of the Indian Contract Act,
          1872; the Information Technology Act, 2000, the rules made thereunder;
          and the amended provisions pertaining to the electronic records in
          various other
        </p>
      </div>

      <Footer />
    </>
  );
};

export default TermsCondition;
