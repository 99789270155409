import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <Header />

      <div className="container AboutSection p-5">
        <p>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          <Link to={"/"}> www.kafipyar.com</Link> (the “Site”).
        </p>
        <h6>Personal information we collect</h6>
        <p>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information”.
        </p>

        <p>
          We collect Device Information using the following technologies: -
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org. - “Log files” track actions occurring
          on the Site, and collect data including your IP address, browser type,
          Internet service provider, referring/exit pages, and date/time stamps.
          - “Web beacons”, “tags”, and “pixels” are electronic files used to
          record information about how you browse the Site.
        </p>

        <p>
          Additionally when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit/debit card numbers, net banking details) email
          address, and phone number. We refer to this information as “Order
          Information”.
        </p>

        <p>
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking both about Device Information and Order Information.
        </p>
        <p>
          How do we use your personal information?
          <br />
          We use the Order Information that we collect generally to fulfil any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information
        </p>

        <p>
          to: <br />
          - Communicate with you;
          <br />
          - Screen our orders for potential risk or fraud; and
          <br />- When in line with the preferences you have shared with us,
          provide you with information or advertising relating to our products
          or services
        </p>

        <p>
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </p>

        <h6>Sharing your personal Information</h6>
        <p>
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. We also use Google
          Analytics to help us understand how our customers use the Site -- you
          can read more about how Google uses your Personal Information here:
          https://www.google.com/intl/en/policies/privacy/. You can also opt-out
          of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
        </p>
        <p>
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>
        <h6>Do not track</h6>

        <p>
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.{" "}
        </p>

        <h6>Data retention</h6>

        <p>
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>
        <h6>Changes</h6>

        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>

        <h6>Contact us</h6>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e mail at kafipyar.customercare@gmail.com or by mail using the
          details provided below:
          <br />
          Kafi Pyar
          <br />
          276 Barkat Nagar, Tonk Phatak,
          <br />
          Jaipur, 302015
          <br />
          Ph: 919353155136
        </p>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
