import React, { useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";

const About = () => {


  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="aboutSection p-5" >
        <p>
          <i class="fa-solid fa-check"></i> &nbsp;  Kafi Pyar is a social enterprise that provides bulk gifting options handcrafted by local women of Rajasthan. As the name signifies, we started as a small initiative to spread love and happiness in the form of festive gifts. This initiative has turned into a mission of providing employment to local women while keeping local art and craft alive. In this way, we cater to your needs by adding joy to your occasions and a sparkle to the women’s eyes.
        </p>
        <p>
          <i class="fa-solid fa-check"></i> &nbsp;   Above all, we believe in affordable and sustainable gifting, and most of our products are cloth-based and can be further reused.
        </p>
        <p>
          <i class="fa-solid fa-check"></i> &nbsp;   We even provide personalized customization services, as we understand that every occasion is important and every requirement is unique. So, what are you waiting for? Let’s spread Kafi Pyar this festive season!
        </p>
        <p>
          <i class="fa-solid fa-check"></i> &nbsp;   Stay in the comfort of your home or office and make the most of our complimentary pick-up
          and drop-off service. Count on us to be your personal car care expert, advisor, and mechanic.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default About;
