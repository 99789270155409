import React, { useEffect, useMemo, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Link, useNavigate } from "react-router-dom";
import "../../style.css";
import { productsAction } from "../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
const ProductCartList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const view_cart = useSelector((state) =>
    state?.product?.view_cart ? state?.product?.view_cart : {}
  );
  const incress_qty = useSelector((state) =>
    state?.product?.incress_qty ? state?.product?.incress_qty : {}
  );
  const decress_qty = useSelector((state) =>
    state?.product?.decress_qty ? state?.product?.decress_qty : {}
  );
  const delete_cart_itme = useSelector((state) =>
    state?.product?.delete_cart_itme ? state?.product?.delete_cart_itme : {}
  );

  const facth_pro = useSelector((state) =>
    state?.product?.facth_pro ? state?.product?.facth_pro : {}
  );
  const add_cart_itme = useSelector((state) =>
    state?.product?.add_cart_itme ? state?.product?.add_cart_itme : ""
  );
  const shipping_charge = useSelector((state) =>
    state?.product?.shipping_charge ? state?.product?.shipping_charge : {}
  );

  const blogids = localStorage.getItem("cart_product");
  const arrs = JSON.parse(blogids && blogids);

  const proId =
    arrs &&
    arrs?.map((data) => {
      return data?.product_id;
    });

  const [itmeChangeid, setitmeChangeid] = useState("");

  var listsoff =
    facth_pro &&
    facth_pro?.filter((x) => {
      return x.product_id != itmeChangeid;
    });

  // const fatchAction = (data) => {
  //     return { type: FATCH_PRO, payload: data };
  //   };

  //   dispatch(fatchAction(listsoff));
  const commentsData = useMemo(() => {
    let computedComments =
      listsoff &&
      listsoff?.map((data, i) => {
        const lists =
          arrs &&
          arrs.filter((x) => {
            return x.product_id == data?.product_id;
          });

        if (lists == null && lists?.length == 0) {
          const emptyArray = [];

          return emptyArray;
        } else {
          data.cart_qty = lists && lists[0]?.cart_qty;
          data.image = data.product_image;
          // data.amount =  data.price
          // data.var = lists && lists[0]?.variations

          return data;
        }
      });

    return computedComments;
  }, [arrs]);

  var totalAmount = 0;

  for (let index = 0; index < commentsData.length; index++) {
    const element = commentsData[index];

    const tamount = element?.cart_qty * element?.price;
    totalAmount += tamount;
  }

  useEffect(() => {
    dispatch(productsAction.shippingcharges());
    return () => {};
  }, []);

  // shippingcharges

  const arr = localStorage.getItem("access_token")
    ? view_cart?.data && view_cart?.data
    : commentsData;

  useEffect(() => {
    dispatch(productsAction.getviewcartdata());
    return () => {};
  }, [incress_qty, decress_qty, delete_cart_itme]);

  const [itmeChange, setitmeChange] = useState([]);

  const increment = (e, price, data, productID) => {
    if (localStorage.getItem("access_token")) {
      const datas = new FormData();
      datas.append("cart_id", data);
      datas.append("price", price);
      const ucount = view_cart?.data?.filter((datass) => {
        return datass?.cart_id == data;
      });
      if (parseInt(ucount[0]?.cart_qty) < parseInt(e)) {
        dispatch(productsAction.incressQty(datas));
        return () => {};
      } else {
        dispatch(productsAction.decressQty(datas));
        return () => {};
      }
    } else {
      const ucount = arr?.filter((datass) => {
        return datass?.product_id == productID;
      });
      if (parseInt(ucount[0]?.cart_qty) < parseInt(e)) {
        // console.log("hjg");
        const finalresult =
          arr &&
          arr?.map((datasm) => {
            datasm.cart_qty =
              datasm?.product_id == productID
                ? datasm?.cart_qty + 1
                : datasm?.cart_qty;
            return datasm;
          });
        setitmeChange(finalresult);
        localStorage.setItem("cart_product", JSON.stringify(finalresult));
      } else {
        const finalresult =
          arr &&
          arr?.map((datasm) => {
            datasm.cart_qty =
              datasm?.product_id == productID
                ? datasm?.cart_qty - 1
                : datasm?.cart_qty;

            return datasm;
          });
        setitmeChange(finalresult);
        localStorage.setItem("cart_product", JSON.stringify(finalresult));
      }
    }
  };

  const deleteCartItmeOne = (e, productId) => {
    // deleteCartItme
    if (localStorage.getItem("access_token")) {
      const datas = new FormData();
      datas.append("cart_id", e);
      dispatch(productsAction.deleteCartItme(datas));
      return () => {};
    } else {
      var lists = arr.filter((x) => {
        return x.product_id != productId;
      });

      setitmeChange(lists);

      localStorage.setItem("cart_product", JSON.stringify(lists));

      setitmeChangeid(productId);
      dispatch(productsAction.addcartItme(productId));
      dispatch(productsAction.fatchPro({ product_ids: proId }));
      return () => {};
    }
  };

  const cartTotalAmunt = localStorage.getItem("access_token")
    ? view_cart?.cart_total
    : totalAmount;

  return (
    <>
      <Header />
      <div>
        <div className="breadcrumbs_area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb_content">
                  <h3>Shopping Cart</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shopping_cart_area">
          <div className="container">
            <form action="#">
              <div className="row">
                <div className="col-12">
                  <div className="table_desc">
                    <div className="cart_page table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th className="product_remove">Delete</th>
                            <th className="product_thumb">Image</th>
                            <th className="product_name">Product</th>
                            <th className="product-price">Price</th>
                            <th className="product_quantity">Quantity</th>
                            <th className="product_total">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {arr &&
                            arr?.map((data, i) => {
                              const amount = data?.price * data?.cart_qty;

                              return (
                                <tr key={i}>
                                  <td className="product_remove">
                                    <a
                                      /* href="#" */ onClick={() => {
                                        deleteCartItmeOne(
                                          data?.cart_id,
                                          data?.product_id
                                        );
                                      }}
                                    >
                                      <i className="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                  <td className="product_thumb">
                                    <a
                                      onClick={() => {
                                        navigate(
                                          "/product-details/" + data?.product_id
                                        );
                                      }}
                                    >
                                      <img src={data?.image} alt="" />
                                    </a>
                                  </td>
                                  <td className="product_name">
                                    <a
                                      onClick={() => {
                                        navigate(
                                          "/product-details/" + data?.product_id
                                        );
                                      }}
                                    >
                                      {data?.product_name}
                                    </a>
                                  </td>
                                  <td className="product-price">
                                    ₹{data?.price}
                                  </td>
                                  <td className="product_quantity">
                                    <label>Quantity</label>{" "}
                                    <input
                                      min="1"
                                      max="100"
                                      value={data?.cart_qty}
                                      onChange={(e) => {
                                        increment(
                                          e.target.value,
                                          data?.price,
                                          data?.cart_id,
                                          data?.product_id
                                        );
                                      }}
                                      type="number"
                                    />
                                  </td>
                                  <td className="product_total">₹{amount}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="coupon_area">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="coupon_code right">
                      <h3>Cart Total</h3>
                      <div className="coupon_inner">
                        <div className="cart_subtotal">
                          <p>Subtotal</p>
                          <p className="cart_amount">₹{cartTotalAmunt}</p>
                        </div>

                        <div className="cart_subtotal">
                          <p>Total</p>
                          <p className="cart_amount">₹{cartTotalAmunt}</p>
                        </div>
                        <div className="checkout_btn">
                          <Link to={"/checkout"}>Proceed to Checkout</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductCartList;
