import axios from 'axios';
import { toast } from "react-toastify";
import { URL } from './url';

let Api = axios.create({
    baseURL: URL.API_BASE_URL,
    headers: localStorage.getItem('token') ? {'Authorization': `Bearer ${localStorage.getItem('token')}`} : undefined
  });

Api.interceptors.response.use(
    (response) => {
     
        return response;
    },
    (error) => {
       
        if (error?.response?.status  == 401) {
            localStorage.removeItem('access_token')
          
        }
        
        return Promise.reject(error);
    }
);
export default Api;