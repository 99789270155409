import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
const ContactUs = () => {
  return (
    <>
      <ScrollToTop />
      <Header />

      <div className="container AboutSection p-5">
        <div className="container text-center">
          <h3 className="p-3">Contact Us</h3>
        </div>
        <div className="col-sm-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14231.8652518177!2d75.7952891!3d26.9045642!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x74bee6cf4a269d54!2sKafi%20Pyar!5e0!3m2!1sen!2sin!4v1671004073275!5m2!1sen!2sin"
            width="1000"
            height="450"
            style={{ border: "0px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        {/* <h6>
          Claritas est etiam processus dynamicus, qui sequitur mutationem
          consuetudium lectorum. Mirum est notare quam littera gothica, quam
          nunc putamus parum claram anteposuerit litterarum formas human. qui
          sequitur mutationem consuetudium lectorum. Mirum est notare quam
        </h6> */}
        <h4 className="pt-3 pb-3">
          Address : 276, Barkat Nagar, Tonk Phatak, Rajasthan 302015
        </h4>
        <h4 className="pt-3 pb-3">Phone Number : +91 7062199421</h4>
        <h4 className="pt-3 pb-3">Email : kafipyar@gmail.com</h4>
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
