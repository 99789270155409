import React, { useEffect, useState } from "react";

import { Country, State, City } from "country-state-city";
import { productsAction } from "../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = document.domain === "localhost";

function Checkoout() {
  const dispatch = useDispatch();
  const navigete = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());

  console.log(startDate);
  console.log(startDate2);

  const [address, setAddress] = useState({ city: "Jaipur" });
  const [showPayMent, setshowPayMent] = useState("0");

  const Countrys = Country.getAllCountries();
  const States = State.getAllStates();
  const Citys = City.getAllCities();

  const view_cart = useSelector((state) =>
    state?.product?.view_cart ? state?.product?.view_cart : {}
  );
  const get_city = useSelector((state) =>
    state?.product?.get_city ? state?.product?.get_city : {}
  );
  const get_address = useSelector((state) =>
    state?.product?.get_address ? state?.product?.get_address : {}
  );
  const check_pincode = useSelector((state) =>
    state?.product?.check_pincode ? state?.product?.check_pincode : {}
  );

  const shipping_charge = useSelector((state) =>
    state?.product?.shipping_charge ? state?.product?.shipping_charge : {}
  );

  const check_discount_code = useSelector((state) =>
    state?.product?.check_discount_code
      ? state?.product?.check_discount_code
      : {}
  );
  const book_order = useSelector((state) =>
    state?.product?.book_order ? state?.product?.book_order : {}
  );

  const [addressSave, setaddress] = useState(false);

  useEffect(() => {
    if (view_cart?.success == false) {
      // console.log("back");
      navigete(-1);
    }
  }, [view_cart]);

  const [first, setfirst] = useState({});

  const hendlecountry = (e) => {
    const selectCountry =
      Countrys &&
      Countrys?.filter((data) => {
        return data?.name == e;
      });
    setfirst(selectCountry[0]);
  };

  const allStates =
    States &&
    States?.filter((data) => {
      return data.countryCode == first?.isoCode;
    });

  useEffect(() => {
    if (get_address && get_address?.length > 0) {
      setAddress({
        ...address,
        address: get_address[0]?.address,
        receiver_mobile: get_address[0]?.receiver_mobile,
        zip: get_address[0]?.zip,
        receiver_name: get_address[0]?.receiver_name,
        city: get_address[0]?.city,
      });
    }
  }, [get_address]);

  const changeAddressHendle = (e) => {
    setAddress({
      ...address,
      address: e?.address,
      receiver_mobile: e?.receiver_mobile,
      zip: e?.zip,
      receiver_name: e?.receiver_name,
      city: e?.city,
    });
    toast.success("Address changeed");
  };

  useEffect(() => {
    dispatch(productsAction.shippingcharges());
    return () => {};
  }, []);

  const [stateM, setstateM] = useState({});

  const hendleState = (e) => {
    const selectCountry =
      States &&
      States?.filter((data) => {
        return data?.name == e;
      });
  };

  const allCitys =
    Citys &&
    Citys?.filter((data) => {
      return data.stateCode == stateM?.isoCode;
    });

  useEffect(() => {
    dispatch(productsAction.getviewcartdata());
    dispatch(productsAction.getAddressdata());
    dispatch(productsAction.getCity({ state_id: "1" }));
    return () => {};
  }, []);

  const addAddress = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });

    if (name == "zip") {
      dispatch(productsAction.checkPincode({ pincode: value }));
      return () => {};
    }
  };

  useEffect(() => {
    if (address) {
      dispatch(productsAction.checkPincode({ pincode: address?.zip }));
      return () => {};
    }
  }, [address]);

  address.country = "India";
  address.state = "Rajasthan";

  const hendleAddress = (e) => {};

  const hedlePaymentBox = (e) => {
    dispatch(productsAction.checkPincode({ pincode: address?.zip }));
    if (check_pincode?.success == true) {
      if (address?.address?.length == 0) {
        toast.error("address require");
      } else if (!address?.email) {
        toast.error("Email Required");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(address?.email)
      ) {
        toast.error("email invalid");
      } else if (address?.receiver_name?.length == 0) {
        toast.error("name require");
      } else if (address?.city?.length == 0) {
        toast.error("City require");
      } else if (address?.zip?.length < 6) {
        toast.error("pincode invalid");
      } else if (address?.receiver_mobile?.length < 10) {
        toast.error("number invalid");
      } else {
        window.scrollTo(2000, 200);
        if (addressSave == true) {
          dispatch(productsAction.addAddress(address));
          setshowPayMent(e);

          return () => {};
        } else {
          // setshowPayMent(e);
          hendlePayment();
        }
      }
    } else {
      toast.error("Sorry we do not delivery in this pincode");
    }
    return () => {};
  };

  useEffect(() => {
    dispatch(productsAction.getviewcartdata());
    return () => {};
  }, []);

  const discountamount =
    view_cart?.cart_total <= shipping_charge?.mini_order
      ? view_cart?.cart_total + parseInt(shipping_charge?.shipping_charges)
      : view_cart?.cart_total;

  const shipCharge =
    view_cart?.cart_total <= shipping_charge?.mini_order
      ? shipping_charge?.shipping_charges
      : 0;

  const totalAmount =
    check_discount_code?.success == true
      ? discountamount - parseInt(check_discount_code?.data?.amount)
      : discountamount;

  const [promo, setpromo] = useState("");

  const checkdiscountcode = (e) => {
    const datas = new FormData();
    datas.append("code", promo);
    dispatch(productsAction.checkdiscountcode(datas));
    return () => {};
  };

  async function displayRazorpay(e) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const amountDetails = {
      amounts: totalAmount,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify(amountDetails),
      redirect: "follow",
    };

    // const data = await fetch('http://localhost:5000/razorpay', requestOptions).then((t) =>
    //     t.json()
    // )

    // const  data = await axios.post('http://192.168.0.65:5500/razorpay', amountDetails, {
    //   headers : {
    //     'Content-Type': 'text/plain'
    //   }
    // }).then((t) =>
    //  t.json()
    //   )

    const options = {
      key: /*  __DEV__ ? */ "rzp_test_4Wrr7LEnAa53c0" /* : 'PRODUCTION_KEY' */,
      currency: "INR",
      amount: 100 * totalAmount.toString(),
      // order_id: data.id,
      name: "Rayna Jwellery",
      description: "Thank you for nothing. Please give us some money",
      image: "/assets/img/logo/logo3.jpeg",
      handler: (res) => {
        const datas = new FormData();
        datas.append("response", "null");
        datas.append("amount", totalAmount);
        datas.append("derlivery_charge", "0");
        datas.append("payment_method", "Razorpay");
        datas.append("transection_id", res?.razorpay_payment_id);
        datas.append("address_id", address?.zip);
        datas.append("total_taxPrice", "0.0");
        datas.append(
          "discount",
          check_discount_code?.success == true
            ? parseInt(check_discount_code?.data?.amount)
            : ""
        );
        datas.append(
          "discountedCode",
          check_discount_code?.success == true
            ? check_discount_code?.data?.code
            : ""
        );
        datas.append("shipping", shipCharge);
        datas.append("selectedDate", "-");
        datas.append("selectedTime", "-");
        datas.append(
          "date_of_function",
          moment(startDate).format("DD/MM/yyyy")
        );
        datas.append(
          "parcel_received_date",
          moment(startDate2).format("DD/MM/yyyy")
        );
        datas.append("other_number", address?.other_number);

        // console.log(startDate);
        // console.log(startDate2);
        datas.append(
          "data",
          JSON.stringify({
            billing_address_address: address?.address,
            billing_address_name: address?.receiver_name,
            billing_address_phone: address?.receiver_mobile,
            billing_pincode: address?.zip,
            city: address?.city,
            country: "India",
            payer_email: address?.email,
            phone: address?.receiver_mobile,
            shipping_bulling_address: address?.address,
            shipping_city: address?.city,
            shipping_company_name: "",
            shipping_country: "India",
            shipping_gst_number: "",
            shipping_name: address?.receiver_name,
            shipping_phone: address?.receiver_mobile,
            shipping_pincode: address?.zip,
            shipping_state: "Rajasthan",
            state: "Rajasthan",
          })
        );

        dispatch(productsAction.bookOrder(datas, navigete));
      },

      prefill: {
        name: address?.receiver_name,
        email: address?.email,
        contact: address?.receiver_mobile,
      },
      notes: {
        userId: "31",
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    paymentObject.open();
  }

  const [PaymentType, setPaymentType] = useState("");

  const heldePayment = (e) => {
    setPaymentType(e);
  };

  const hendlePayments = (e) => {
    if (PaymentType == "RazarPay") {
      displayRazorpay();
    } else if (PaymentType == "COD") {
      hendlePayment();
    } else {
      toast.error("choose a payment method");
    }
  };

  const hendlePayment = (e) => {
    if (check_pincode?.success == true) {
      const datas = new FormData();
      datas.append("response", "null");
      datas.append("amount", totalAmount);
      datas.append("derlivery_charge", "0");
      datas.append("payment_method", "COD");
      datas.append("transection_id", Date.now());
      datas.append("address_id", address?.zip);
      datas.append("total_taxPrice", "0.0");
      datas.append(
        "discount",
        check_discount_code?.success == true
          ? parseInt(check_discount_code?.data?.amount)
          : ""
      );
      datas.append(
        "discountedCode",
        check_discount_code?.success == true
          ? check_discount_code?.data?.code
          : ""
      );
      datas.append("shipping", shipCharge);
      datas.append("selectedDate", startDate);
      datas.append("selectedTime", "-");
      datas.append("date_of_function", moment(startDate).format("DD/MM/yyyy"));
      datas.append(
        "parcel_received_date",
        moment(startDate2).format("DD/MM/yyyy")
      );
      datas.append("other_number", address?.other_number);

      datas.append(
        "data",
        JSON.stringify({
          billing_address_address: address?.address,
          billing_address_name: address?.receiver_name,
          billing_address_phone: address?.receiver_mobile,
          billing_pincode: address?.zip,
          city: address?.city,
          country: "India",
          payer_email: address?.email,
          phone: address?.receiver_mobile,
          shipping_bulling_address: address?.address,
          shipping_city: address?.city,
          shipping_company_name: "",
          shipping_country: "India",
          shipping_gst_number: "",
          shipping_name: address?.receiver_name,
          shipping_phone: address?.receiver_mobile,
          shipping_pincode: address?.zip,
          shipping_state: "Rajasthan",
          state: "Rajasthan",
        })
      );
      dispatch(productsAction.bookOrder(datas, navigete));
      return () => {};
    } else {
      toast.error(check_pincode?.message);
    }
  };

  const hendeladdres = (e) => {
    setaddress(e?.target?.checked);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <ScrollToTop />
      <Header />

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        // size="lg"
        // keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Raise a Query </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="checkout_form">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <form action="#">
                  <h3>Billing Details</h3>

                  <div className="row">
                    <div className="col-lg-12 mb-20">
                      <label>
                        {" "}
                        Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="receiver_name"
                        value={address?.receiver_name}
                        onChange={(e) => {
                          addAddress(e);
                        }}
                      />
                    </div>
                    <div className="col-lg-12 mb-20">
                      <label>
                        {" "}
                        Email <span>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={address?.email}
                        onChange={(e) => {
                          addAddress(e);
                        }}
                      />
                    </div>

                    <div className="col-12 mb-20">
                      <label>
                        Street address <span>*</span>
                      </label>
                      <input
                        placeholder="House number and street name"
                        value={address?.address}
                        type="text"
                        onChange={(e) => {
                          addAddress(e);
                        }}
                        name="address"
                      />
                    </div>

                    {/* <div className="col-12 mb-20">
                        <label for="country">
                          country <span>*</span>
                        </label>
                        <select
                          className="niceselect_option sel"
                          name="cuntry"
                          id="country"
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        >
                          <option value={"India"}>India</option>
                        </select>
                      </div>

                      <div className="col-12 mb-20">
                        <label for="country">
                          State <span>*</span>
                        </label>
                        <select
                          className="niceselect_option sel"
                          name="state"
                          id="state"
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        >
                          <option value={"Rajasthan"}>Rajasthan</option>
                        </select>
                      </div>
                      <div className="col-12 mb-20">
                        <label for="country">
                          Town / City <span>*</span>
                        </label>
                        <select
                          className="niceselect_option sel"
                          id="city"
                          name="city"
                          value={address?.city}
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        >
                          <option value={""}>Select city</option>

                          {get_city &&
                            get_city?.map((data, i) => {
                              return (
                                <option value={data?.name} key={i}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-lg-6 mb-20">
                        <label>
                          Phone<span>*</span>
                        </label>
                        <input
                          type="text"
                          name="receiver_mobile"
                          value={address?.receiver_mobile}
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        />
                      </div>
                      <div className="col-lg-6 mb-20">
                        <label>
                          {" "}
                          Pincode <span>*</span>
                        </label>
                        <input
                          type="number"
                          value={address?.zip}
                          name="zip"
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        />
                      </div>
                      <div className="col-12 mb-20">
                        <label for="country">
                          funcation date <span>*</span>
                        </label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                      </div>
                      <div className="col-12 mb-20">
                        <input
                          id="account"
                          type="checkbox"
                          checked={addressSave}
                          onClick={(e) => {
                            hendeladdres(e);
                          }}
                          data-bs-target="createp_account"
                        />
                        <label
                          for="account"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-controls="collapseOne"
                        >
                          Save address
                        </label>
                      </div> */}

                    {/* <div className="cart_button view_cart editAddBtn">
                        <a
                          onClick={() => {
                            hedlePaymentBox("1");
                          }}
                        >
                          Proceed to Payment{" "}
                        </a>
                      </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Submit Query</Button>
        </Modal.Footer>
      </Modal>

      <div className="breadcrumbs_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_content">
                <h3>Checkout</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Checkout_section" id="accordion">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {showPayMent == "0" ? (
                <div className="user-actions">
                  <h3>
                    {/* <i className="fa fa-file-o" aria-hidden="true"></i> */}

                    <a
                      className="Returning"
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#checkout_coupon1"
                      aria-expanded="true"
                    >
                      Change Address
                    </a>
                  </h3>
                  <div
                    id="checkout_coupon1"
                    className="collapse"
                    data-parent="#accordion"
                  >
                    <div className="checkout_info">
                      <div className="row">
                        {get_address &&
                          get_address?.map((data, i) => {
                            return (
                              <div className="col-12 col-sm-6">
                                <div
                                  className="addressCard"
                                  onClick={(e) => {
                                    changeAddressHendle(data);
                                  }}
                                >
                                  <p>Name :{data?.receiver_name}</p>
                                  <p>Number :{data?.receiver_mobile}</p>
                                  <p>Address :{data?.address}</p>
                                  <p>Pincode :{data?.zip}</p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="user-actions">
                <h3>
                  {/* <i className="fa fa-file-o" aria-hidden="true"></i> */}

                  <a
                    className="Returning"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#checkout_coupon"
                    aria-expanded="true"
                  >
                    Click here to enter your code
                  </a>
                </h3>
                <div
                  id="checkout_coupon"
                  className="collapse show"
                  data-parent="#accordion"
                >
                  <div className="checkout_info">
                    <input
                      placeholder="Coupon code"
                      type="text"
                      onChange={(e) => {
                        setpromo(e?.target?.value);
                      }}
                    />
                    <button type="submit" onClick={checkdiscountcode}>
                      Apply coupon
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="checkout_form">
            <div className="row">
              {showPayMent == "0" ? (
                <div className="col-lg-6 col-md-6">
                  <form action="#">
                    <h3>Billing Details</h3>

                    <div className="row">
                      <div className="col-lg-12 mb-20">
                        <label>
                          {" "}
                          Name <span>*</span>
                        </label>
                        <input
                          type="text"
                          name="receiver_name"
                          value={address?.receiver_name}
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        />
                      </div>
                      <div className="col-lg-12 mb-20">
                        <label>
                          {" "}
                          Email <span>*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={address?.email}
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        />
                      </div>

                      <div className="col-12 mb-20">
                        <label>
                          Street address <span>*</span>
                        </label>
                        <input
                          placeholder="House number and street name"
                          value={address?.address}
                          type="text"
                          onChange={(e) => {
                            addAddress(e);
                          }}
                          name="address"
                        />
                      </div>

                      <div className="col-12 mb-20">
                        <label for="country">
                          country <span>*</span>
                        </label>
                        <select
                          className="niceselect_option sel"
                          name="cuntry"
                          id="country"
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        >
                          <option value={"India"}>India</option>
                        </select>
                      </div>

                      <div className="col-12 mb-20">
                        <label for="country">
                          State <span>*</span>
                        </label>
                        <select
                          className="niceselect_option sel"
                          name="state"
                          id="state"
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        >
                          <option value={"Rajasthan"}>Rajasthan</option>
                        </select>
                      </div>
                      <div className="col-12 mb-20">
                        <label for="country">
                          Town / City <span>*</span>
                        </label>
                        <select
                          className="niceselect_option sel"
                          id="city"
                          name="city"
                          value={address?.city}
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        >
                          <option value={""}>Select city</option>

                          {get_city &&
                            get_city?.map((data, i) => {
                              return (
                                <option value={data?.name} key={i}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-lg-6 mb-20">
                        <label>
                          Phone<span>*</span>
                        </label>
                        <input
                          type="text"
                          name="receiver_mobile"
                          value={address?.receiver_mobile}
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        />
                      </div>
                      <div className="col-lg-6 mb-20">
                        <label>
                          {" "}
                          Pincode <span>*</span>
                        </label>
                        <input
                          type="number"
                          value={address?.zip}
                          name="zip"
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        />
                      </div>

                      <div className="col-6 mb-20">
                        <label for="country">
                          function date <span>*</span>
                        </label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                      </div>
                      <div className="col-6 mb-20">
                        <label for="country">
                          Received Date <span>*</span>
                        </label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={startDate2}
                          onChange={(date) => setStartDate2(date)}
                        />
                      </div>
                      <div className="col-lg-6 mb-20">
                        <label>
                          {" "}
                          Other Number <span>*</span>
                        </label>
                        <input
                          type="number"
                          value={address?.other_number}
                          name="other_number"
                          onChange={(e) => {
                            addAddress(e);
                          }}
                        />
                      </div>
                      {/* <div className="col-12 mb-20">
                        <input
                          id="account"
                          type="checkbox"
                          checked={addressSave}
                          onClick={(e) => {
                            hendeladdres(e);
                          }}
                          data-bs-target="createp_account"
                        />
                        <label
                          for="account"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-controls="collapseOne"
                        >
                          Save address
                        </label>
                      </div> */}

                      <div className="cart_button view_cart editAddBtn">
                        <a
                          onClick={() => {
                            hedlePaymentBox("1");
                          }}
                        >
                          Raise a Query
                        </a>
                      </div>

                      {/* <Button variant="primary" onClick={handleShow}>
                        Proceed to Payment
                      </Button> */}
                    </div>
                  </form>
                </div>
              ) : (
                <div className="col-lg-6 col-md-6">
                  <div className="payment_method">
                    <div className="panel-default">
                      <input
                        id="payment"
                        name="payment_method"
                        type="radio"
                        value={"COD"}
                        onClick={(e) => heldePayment(e.target.value)}
                        data-bs-target="createp_account"
                      />
                      <label
                        for="payment"
                        data-bs-toggle="collapse"
                        data-bs-target="#method"
                        aria-controls="method"
                      >
                        cash on delivery
                      </label>
                    </div>
                    <div className="panel-default">
                      <input
                        id="payment_defult"
                        name="payment_method"
                        value={"RazarPay"}
                        onClick={(e) => heldePayment(e.target.value)}
                        type="radio"
                        data-bs-target="createp_account"
                      />
                      <label
                        for="payment_defult"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsedefult"
                        aria-controls="collapsedefult"
                      >
                        Online{" "}
                        {/* <img src="assets/img/icon/papyel.png" alt="" /> */}
                      </label>
                    </div>
                    <div className="order_button">
                      <button onClick={hendlePayments}>Continue</button>
                    </div>
                    <div className="cart_button view_cart editAddBtn">
                      <a
                        onClick={() => {
                          hedlePaymentBox("0");
                        }}
                      >
                        Edit Address{" "}
                      </a>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-6 col-md-6">
                <form action="#">
                  <h3>Your order</h3>
                  <div className="order_table table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {view_cart?.data &&
                          view_cart?.data?.map((data, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {" "}
                                  {data?.product_name}{" "}
                                  <strong> × {data?.cart_qty}</strong>
                                </td>
                                <td>₹{data?.price}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Cart Subtotal</th>
                          <td>₹{view_cart?.cart_total}</td>
                        </tr>
                        <tr>
                          <th>Shipping</th>
                          <td>
                            <strong>
                              ₹
                              {view_cart?.cart_total <=
                              shipping_charge?.mini_order
                                ? shipping_charge?.shipping_charges
                                : 0}
                            </strong>
                          </td>
                        </tr>
                        {check_discount_code?.success == true && (
                          <tr>
                            <th>Discount</th>
                            <td>
                              <strong>
                                ₹{check_discount_code?.data?.amount}
                              </strong>
                            </td>
                          </tr>
                        )}
                        <tr className="order_total">
                          <th>Order Total</th>
                          <td>
                            <strong>₹ {totalAmount} </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="payment_method"></div>
                </form>
                <div className="product_variant  mt-5">
                  {/* <label>quantity</label>
                                <input min="1" max="100" value="1" type="number" /> */}
                  <button
                    className="button2"
                    type="submit"
                    onClick={(e) => {
                      navigete("/shipping");
                    }}
                  >
                    Shipping Policy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Checkoout;
