export const LOADING = "LOADING";
//user constant

export const REGISTER = "REGISTER";
export const SEND_OTP = "SEND_OTP";
export const OTP_VERIFY = "OTP_VERIFY";
export const LOGIN = "LOGIN";

export const HOME_DATA = "HOME_DATA";

export const ADD_CART = "ADD_CART";
export const VIEW_CART = "VIEW_CART";
export const INCRESS_QTY = "INCRESS_QTY";
export const DECRESS_QTY = "DECRESS_QTY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const CATEGORY_PRODUCT = "CATEGORY_PRODUCT";
export const GET_CATEGORY = "GET_CATEGORY";
export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const GET_ADDRESS = "GET_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const GET_CITY = "GET_CITY";
export const FATCH_PRO = "FATCH_PRO";

export const BOOK_ORDER = "BOOK_ORDER";
export const ORDER_ORDER = "ORDER_ORDER";
export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const GET_ORDER = "GET_ORDER";
export const CHECK_PINCODE = "CHECK_PINCODE";

export const SHIPPING_CHARGE = "SHIPPING_CHARGE";
export const CHECK_DISCOUNT_CODE = "CHECK_DISCOUNT_CODE";
export const STATIC_PAGES = "STATIC_PAGES";
export const CANCLE_ORDER = "CANCLE_ORDER";
export const GET_QUERY_BY_STATUS = "GET_QUERY_BY_STATUS";

// cart itme  localStorage
export const CART_ITME_ADD = "CART_ITME_ADD";
