import {
    LOADING,
    REGISTER,
    SEND_OTP,
    LOGIN,
    OTP_VERIFY,
    
  
  
  
  } from "../common/constant";
  
  const initialState = {
    loading: false,
  
    signup_with_email: {},
    send_otp: {},
    otp_verify: {},
    login: {},
  };
  
  export default (state = initialState, action) => {
  
  
  
    switch (action.type) {
      case LOADING:
        return { ...state, loading: action.payload };
  
      case REGISTER:
        return { ...state, signup_with_number: action.payload };
      case SEND_OTP:
        return { ...state, send_otp: action.payload };
      case OTP_VERIFY:
        return { ...state, otp_verify: action.payload };
      case LOGIN:
        return { ...state, login: action.payload };
  
    
     
        
      default:
        return state;
    }
  };