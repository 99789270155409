import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import All from "./components/All";
import SweetSale from "./components/SweetSale";
import WeddingOrder from "./components/WeddingOrder";
import ProductCartList from "./components/ProductCartList/ProductCartList";
import Login from "./components/Authentication/Login";
import Account from "./Screen/Account/Account";
import AuthAdmin from "../src/Routes/AuthAdmin";
import ContactUs from "./components/ContactUs";
import TermsCondition from "./components/Terms&Condition";
import ShippingPolicy from "./components/ShippingPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import RefundPolicy from "./components/RefundPolicy";
import Checkoout from "./components/Checkoout/Checkoout";
import ProductDetails from "./components/ProductDetails";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import { useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Faq from "./components/Faq";
import Category from "./components/Category/Category";
import BulkOurder from "./components/BulkOurder";
import OrderDetails from "./components/OrderDetails/OrderDetails";
function App() {
  const [first, setfirst] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setfirst(false);
    }, 1000);
  }, []);

  return (
    <>
      {first == true && <LoadingScreen />}
      <a
        className="whatsappIconH"
        target={"_blank"}
        href="https://api.whatsapp.com/send?phone=+917062199421&text=Hi, I have an enquiry for Kafi Pyar Gifts"
      >
        <WhatsAppIcon className="text-white p"></WhatsAppIcon>
      </a>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/customization" element={<All />} />
          <Route path="/bulk-order" element={<BulkOurder />} />
          <Route path="/shop" element={<SweetSale />} />
          <Route path="/category" element={<Category />} />
          <Route path="/wedding-orders" element={<WeddingOrder />} />
          <Route path="/order-details/:id" element={<OrderDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/products-cart" element={<ProductCartList />} />
          {/* <Route path="/checkout" element={<Checkoout />} /> */}
          <Route path="/checkout" element={<AuthAdmin cmp={Checkoout} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms" element={<TermsCondition />} />
          <Route path="/shipping" element={<ShippingPolicy />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />

          <Route path="/account" element={<AuthAdmin cmp={Account} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
