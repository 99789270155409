import React from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GoogleIcon from '@mui/icons-material/Google';
import { NavLink } from "react-router-dom";
import "../../App.css";

// import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  return (
    <>
      {/* Footer Start */}
      <div className="MainFooter container-fluid  mt-5 pt-3">
        <div className="row m-auto pt-5">
          <div className="col-sm-3  FooterSection1 p-3" >
            <NavLink to="/shipping" className="NavLink">
              <p>Shipping Policy</p>
            </NavLink>
          
            <NavLink to="/privacy" className="NavLink">
              <p>Privacy Policy</p>
            </NavLink>
            <NavLink to="/refund" className="NavLink">
              <p>Refund Policy</p>
            </NavLink>
          </div>
         
          <div className="col-sm-3  FooterSection1 p-3">
            <NavLink to="/about" className="NavLink">
              <p>About</p>
            </NavLink>
            <NavLink to="/contact" className="NavLink">
              <p>Contact Us</p>
            </NavLink>
            {/* <NavLink to="/contact" className="NavLink">
              <p>FAQ</p>
            </NavLink> */}
          
           
          </div>
          <div className="col-sm-3 FooterSection1  p-3">
            
            <div className="row col-6 SocialMediaIcons">
              <div className="col-3">
                <a
                  href="https://www.facebook.com/kaafi.pyaar?mibextid=ZbWKwL"
                  target={"_blank"}
                >
                  <FacebookIcon className="socailIcon"></FacebookIcon>
                </a>
              </div>
              <div className="col-3">
                <a
                  href="https://www.instagram.com/kafipyar/?igshid=YmMyMTA2M2Y%3D"
                  target={"_blank"}
                >
                  <InstagramIcon className="socailIcon"></InstagramIcon>
                </a>
              </div>
              <div className="col-3">
                <a
                  href="https://www.pinterest.com/kafipyar02/"
                  target={"_blank"}
                >
                  <PinterestIcon className="socailIcon"></PinterestIcon>
                </a>
              </div>
              <div className="col-3">
                {/* <PinterestIcon className="socailIcon"></PinterestIcon> */}
                <a href="https://g.co/kgs/qq2HbSa" target={"_blank"}>
                  <GoogleIcon className="socailIcon"></GoogleIcon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default Footer;
