import React, { useEffect } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

function Faq() {

  return (
    <div>
      <Header />
      <div className="breadcrumbs_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_content">
                <h3>Frequently Questions</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div id="accordion" className="card__accordion">
                <h4 className="mb-4">Shipping policy</h4>
                <div className="card card_dipult">
                  <div className="card-header card_accor" id="headingOne">
                    <button
                      className="btn btn-link"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How long does the shipping take?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        You will receive the products within 3-15 business days
                        of placing the order depending upon the delivery
                        location. For further information please read our
                        Shipping Policy.
                      </p>
                      {/* <p>
                        Do you ship all over India and internationally? We can
                        ship our products almost anywhere in India and across
                        the globe other than a few locations which our logistic
                        partners do not serve.
                      </p>
                      <p>
                        How do I track my order? After your product has been
                        shipped from our end, you will receive an email with the
                        tracking details of your order.
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="card card_dipult">
                  <div className="card-header card_accor" id="headingOnetwo">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOnetwo"
                      aria-expanded="false"
                      aria-controls="collapseOnetwo"
                    >
                      Do you ship all over India and internationally?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>

                  <div
                    id="collapseOnetwo"
                    className="collapse "
                    aria-labelledby="headingOnetwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <p>
                        How long does the shipping take? You will receive the
                        products within 3-15 business days of placing the order
                        depending upon the delivery location. For further
                        information please read our Shipping Policy.
                      </p> */}
                      <p>
                        We can ship our products almost anywhere in India and
                        across the globe other than a few locations which our
                        logistic partners do not serve.
                      </p>
                      {/* <p>
                        How do I track my order? After your product has been
                        shipped from our end, you will receive an email with the
                        tracking details of your order.
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="card card_dipult">
                  <div className="card-header card_accor" id="headingOnethree">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOnetherr"
                      aria-expanded="false"
                      aria-controls="collapseOnetherr"
                    >
                      How do I track my order?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>

                  <div
                    id="collapseOnetherr"
                    className="collapse "
                    aria-labelledby="headingOnethree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <p>
                        How long does the shipping take? You will receive the
                        products within 3-15 business days of placing the order
                        depending upon the delivery location. For further
                        information please read our Shipping Policy.
                      </p>
                      <p>
                        Do you ship all over India and internationally? We can
                        ship our products almost anywhere in India and across
                        the globe other than a few locations which our logistic
                        partners do not serve.
                      </p> */}
                      <p>
                        After your product has been shipped from our end, you
                        will receive an email with the tracking details of your
                        order.
                      </p>
                    </div>
                  </div>
                </div>

                <h4 className="mb-4 mt-5"> Returns and exchanges</h4>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingTwo">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Do you accept returns?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We have a No Return, No Refund policy. In case of any
                        issue you can exchange your product with any product of
                        the same or higher value from our product range.
                      </p>
                      {/* <p>
                        What to do if I receive my order in a damaged condition?
                        Please email us at customercare@kafipyar.com stating
                        your issue. Someone from our team will review your
                        complaint and you will be sent a replacement of your
                        order.
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingTwotwo">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwotwo"
                      aria-expanded="false"
                      aria-controls="collapseTwotwo"
                    >
                      What to do if I receive my order in a damaged condition?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapseTwotwo"
                    className="collapse"
                    aria-labelledby="headingTwotwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <p>
                        Do you accept returns? We have a No Return, No Refund
                        policy. In case of any issue you can exchange your
                        product with any product of the same or higher value
                        from our product range.
                      </p> */}
                      <p>
                        Please email us at customercare@kafipyar.com stating
                        your issue. Someone from our team will review your
                        complaint and you will be sent a replacement of your
                        order.
                      </p>
                    </div>
                  </div>
                </div>

                <h4 className="mb-4 mt-5"> Product Policies</h4>

                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingfour">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeight"
                      aria-expanded="false"
                      aria-controls="collapseeight"
                    >
                      What are the materials used?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapseeight"
                    className="collapse"
                    aria-labelledby="headingfour"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        All the products are made of different type of fabrics
                        like silk dupion, muslin, Banarasi Chanderi etc. Details
                        of the materials are specified with each product.
                      </p>
                      {/* <p>
                      asdasd
                      </p> */}
                    </div>
                  </div>
                </div>

                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingThree">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      How do I exchange my product?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        - We have a 30-day exchange policy, which means you have
                        30 days after receiving your item to request an exchange
                        with the same or any product of equivalent or more value
                        than the original product.
                      </p>
                      <p>
                        - To be eligible for an exchange, your item must be in
                        the same condition that you received it, unworn or
                        unused, with tags, and in its original packaging. You’ll
                        also need the receipt or proof of purchase.
                      </p>
                      <p>
                        - To start an exchange, you can contact us at
                        customercare@kafipyar.com. If your request is accepted,
                        we’ll send you the shipping details as well as
                        instructions on how and where to send your package.
                        Items sent back to us without first requesting an
                        exchange will not be accepted. -Products availed after
                        exchange will be dispatched from our end only after
                        receiving back the original product.
                      </p>
                      <p>
                        - For further queries please read our Exchange Policy.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingfive">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseseven"
                      aria-expanded="false"
                      aria-controls="collapseseven"
                    >
                      How do I clean my product?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapseseven"
                    className="collapse"
                    aria-labelledby="headingfive"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>Dry clean only. Do not hand-wash / machine-wash.</p>
                    </div>
                  </div>
                </div>

                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingsix">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      Where are your products manufactured?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsefour"
                    className="collapse"
                    aria-labelledby="headingsix"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>All our products are handcrafted in house.</p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingseven">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      Are the products and colours same as shown in the image?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsefive"
                    className="collapse"
                    aria-labelledby="headingseven"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We ensure that our products are shot in standard light
                        giving colours truest and nearest to our products.
                        However, due to the intricacy of work involved in our
                        products there may be minor variations in the colour of
                        the fabric, dyes, prints or embroideries of the product
                        image you see on your screen and the actual product.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeight">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesix"
                      aria-expanded="false"
                      aria-controls="collapsesix"
                    >
                      Do you provide gift packing services?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesix"
                    className="collapse"
                    aria-labelledby="headingeight"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No we only provide handcrafted products used for packing
                        and gifting. We do not pack them for you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeights">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixs"
                      aria-expanded="false"
                      aria-controls="collapsesixs"
                    >
                      Do you retail physically?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixs"
                    className="collapse"
                    aria-labelledby="headingeights"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No our products are only available to shop online
                        through our website or our Instagram page @kafipyar. For
                        any further information you can contact us at
                        customercare@kafipyar.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeightvs">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixss"
                      aria-expanded="false"
                      aria-controls="collapsesixss"
                    >
                      Do you customize your products?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixss"
                    className="collapse"
                    aria-labelledby="headingeightvs"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We do offer customizations. Please refer to our
                        customization policy.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeightns">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixssss"
                      aria-expanded="false"
                      aria-controls="collapsesixssss"
                    >
                      Are you into re-selling?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixssss"
                    className="collapse"
                    aria-labelledby="headingeightns"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No we do not promote re-selling of our products and do
                        not offer any special discounts or offers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeightdss">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixxs"
                      aria-expanded="false"
                      aria-controls="collapsesixxs"
                    >
                      What discounts do you offer if we place a bulk order?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixxs"
                    className="collapse"
                    aria-labelledby="headingeightdss"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We already sell at bulk price the best price possible
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeightrrrs">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixvs"
                      aria-expanded="false"
                      aria-controls="collapsesixvs"
                    >
                      Do I need to create an account to place my order?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixvs"
                    className="collapse"
                    aria-labelledby="headingeightrrrs"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We have a guest checkout service available. Creating an
                        account is not necessary.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeightmmss">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixbs"
                      aria-expanded="false"
                      aria-controls="collapsesixbs"
                    >
                      For any further queries please contact us at:
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixbs"
                    className="collapse"
                    aria-labelledby="headingeightmmss"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>customercare@kafipyar.com</p>
                    </div>
                  </div>
                </div>
                {/* <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeight">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesix"
                      aria-expanded="false"
                      aria-controls="collapsesix"
                    >
                      Do I need to create an account to place my order?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesix"
                    className="collapse"
                    aria-labelledby="headingeight"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        We have a guest checkout service available. Creating an
                        account is not necessary.
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeightvcss">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixds"
                      aria-expanded="false"
                      aria-controls="collapsesixds"
                    >
                      How to request a sample?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixds"
                    className="collapse"
                    aria-labelledby="headingeightvcss"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Contact us at customercare@kafipyar.com and let us know
                        which sample you want. The team will look into it and
                        get back to you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeighttttr">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixms"
                      aria-expanded="false"
                      aria-controls="collapsesixms"
                    >
                      Could we receive A Sample before the bulk purchase?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixms"
                    className="collapse"
                    aria-labelledby="headingeighttttr"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, a sample can be bought before the final purchase.
                        But the applicable price for the sample will be retail
                        price and if the bulk order gets confirmed we will
                        manage the balance
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeightnrf">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixns"
                      aria-expanded="false"
                      aria-controls="collapsesixns"
                    >
                      What colour/ design comes in bulk order?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixns"
                    className="collapse"
                    aria-labelledby="headingeightnrf"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Assorted colour/design of same category of product comes
                        in bulk order. For same colour/design you can contact us
                        at customercare@kafipyar.com the team will look into it
                        and get back to you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeightfffd">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixys"
                      aria-expanded="false"
                      aria-controls="collapsesixys"
                    >
                      Can one single piece be bought?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixys"
                    className="collapse"
                    aria-labelledby="headingeightfffd"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>No, we only sell in bulk</p>
                    </div>
                  </div>
                </div>
                <div className="card  card_dipult">
                  <div className="card-header card_accor" id="headingeighttere">
                    <button
                      className="btn btn-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesixus"
                      aria-expanded="false"
                      aria-controls="collapsesixus"
                    >
                      What is minimum order quantity?
                      <i className="fa fa-plus"></i>
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <div
                    id="collapsesixus"
                    className="collapse"
                    aria-labelledby="headingeighttere"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>10 pcs & above</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
