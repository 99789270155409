import React, { useEffect, useState } from "react";
import "../../App.css";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { productsAction } from "../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";

const ShoppingCards = () => {
  const navigate = useNavigate();
  const categoryId = useLocation();

  const dispatch = useDispatch();

  // category_product

  const category_product = useSelector((state) =>
    state?.product?.category_product ? state?.product?.category_product : {}
  );

  const [category, setCategory] = useState([]);

  useEffect(() => {
    console.log(category_product, "category_product 1");
    if (category_product?.subcategory) {

      setCategory(category_product?.subcategory);
    }
  }, [category_product?.subcategory]);




  const get_category = useSelector((state) =>
    state?.product?.get_category ? state?.product?.get_category : []
  );

  const [productDetails, setproductDetails] = useState({});
  const [procaetgory, setProcaetgory] = useState("");

  const [test, settest] = useState([""]);
  const [AllData, setAllData] = useState([]);

  const [active, setActive] = useState('')
  const [subactive, setSubActive] = useState('')


  console.log(categoryId);
  useEffect(() => {
    if (categoryId?.state?.id !== undefined) {
      setActive(categoryId?.state?.id)
      setProcaetgory(categoryId?.state?.id);
    }
  }, [categoryId?.state?.id]);

  const filID = procaetgory;

  const keys = ["product_id"];

  const filtereds =
    AllData &&
    AllData?.filter(
      (
        (s) => (o) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
      )(new Set())
    );

  const filtered =
    filtereds &&
    filtereds?.filter((data) => {
      return data.category_id == filID;
    });


  useEffect(() => {
    if (category_product?.products !== undefined) {
      for (const obj of category_product?.products &&
        category_product?.products) {
        settest(obj);
        AllData.push(obj);
      }
    }

  }, [category_product]);

  const hendleproductDetails = (e) => {
    const datas =
      category_product?.products &&
      category_product?.products?.filter((data) => {
        return data?.product_id == e;
      });

    setproductDetails(datas[0]);
  };

  const [itmeCount, setItmeCount] = useState(3);
  const [loader, setLoader] = useState(false);

  const hendlePagination = async (e) => {
    setLoader(true);
    try {
      var sub = ''
      if (subactive) {
        sub = subactive
      }
      const itme = itmeCount + 8;
      setItmeCount(itme);
      const datas = new FormData();
      sub && datas.append("sub_category_id", sub)
      datas.append("category_id", filID);
      datas.append("limit", "8");
      datas.append("ofset", itmeCount);

      dispatch(productsAction.getCategoryProduct({ data: datas, type: sub ? "sub" : '' }));
      dispatch(productsAction.getCategory());
    } catch (error) {
      console.error('Error during pagination:', error);
    } finally {
      setLoader(false);
    }

    return () => { };
  };

  const [action, setAction] = useState(false);

  useEffect(() => {
    const datas = new FormData();
    datas.append("category_id", categoryId?.state?.id);
    datas.append("limit", "8");
    datas.append("ofset", "0");
    dispatch(productsAction.getCategoryProduct({ data: datas, type: '' }));
    dispatch(productsAction.getCategory());
    return () => { };
  }, [categoryId?.state?.id, action]);

  const cateproduct = ({ sub }) => {
    const datas = new FormData();

    sub && datas.append("sub_category_id", sub)
    datas.append("category_id", filID);
    datas.append("limit", "8");
    datas.append("ofset", "0");
    dispatch(productsAction.getCategoryProduct({ data: datas, type: 'sub' }));
    setProcaetgory(procaetgory);
    setItmeCount(8);
    setAllData([]);
    return () => { };
  };

  const [itmeChange, setitmeChange] = useState([]);

  const [ctQ, setctQ] = useState(10);

  console.log(ctQ);

  const disbt = () => {
    const cs = ctQ - 1;

    if (10 > cs) {
      toast.error("minimum 10 product");
    } else {
      setctQ(cs);
    }
  };
  const inbt = () => {
    const cs = ctQ + 1;

    // if (10<cs) {
    //   toast.error("minimum 10 product")
    // } else {
    setctQ(cs);
    // }
  };

  const enterProductCount = (e) => {
    // console.log(e);

    if (e) {
      const numAm = parseInt(e);

      // if (10 >= numAm) {
      //   toast.error("minimum 10 product select");
      // } else {
      setctQ(numAm);
    } else {
      setctQ(e);
    }

    // }
  };

  const addtoItme = (e) => {
    if (10 > ctQ) {
      toast.error("minimum 10 product select");
    } else {
      if (localStorage.getItem("access_token")) {
        const datas = new FormData();

        datas.append("product_id", e);
        datas.append("qty", ctQ);
        dispatch(productsAction.addCartItme(datas));
        setTimeout(() => {
          dispatch(productsAction.getviewcartdata());
        }, 1000);
        return () => { };
      } else {
        if (e !== undefined) {
          const blogIds = localStorage.getItem("cart_product");
          const blogIdss = blogIds !== null ? blogIds : "[]";
          const arr = JSON.parse(blogIdss);
          const alredyAdd = arr.indexOf(e);
          var lists = arr.filter((x) => {
            return x.product_id == e;
          });
          if (lists?.length == 0) {
            arr.push({ product_id: e, cart_qty: ctQ });
            setitmeChange(arr);
            localStorage.setItem("cart_product", JSON.stringify(arr));
            setTimeout(() => {
              const blogIds = localStorage.getItem("cart_product");
              const blogIdss = blogIds !== null ? blogIds : "[]";
              const arr = JSON.parse(blogIdss);
              const proId =
                arr &&
                arr?.map((data) => {
                  return data?.product_id;
                });
              toast.success("itme added  to cart");
              dispatch(productsAction.addcartItme(""));
              dispatch(productsAction.fatchPro({ product_ids: proId }));
              return () => { };
            }, 500);
          } else {
            const finalresult =
              arr &&
              arr?.map((data) => {
                return data;
              });

            setitmeChange(arr);
            toast.success("itme already add");
            localStorage.setItem("cart_product", JSON.stringify(finalresult));
          }
        }
      }
    }
  };

  const [ModalData, setModalData] = useState("");

  const ModalBox = (FetchedData) => {
    setModalData(FetchedData);
    setctQ(10);
  };
  // console.log(filtered);
  return (
    <>
      {/* Modal Box  Start */}
      <div
        className="modal fade "
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"

      >
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content ">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body  col-12" >
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-4 ">
                    <div className=" bg-dark">
                      <img
                        src={ModalData.product_image}
                        className="h-50 w-100 "
                      />
                    </div>
                  </div>
                  <div className="col-sm-8  ModalText">
                    <h5> {ModalData.product_name} </h5>
                    <h6>
                      ₹{ModalData?.price}
                    </h6>

                    <div class="js-qty__wrapper  ms-3">
                      <input
                        type="text"
                        id="Quantity-5695950618786"
                        class="js-qty__num"
                        value={ctQ}
                        min="10"
                        aria-label="quantity"
                        pattern="[0-9]*"
                        name="quantity"
                        mm-min={"10"}
                        mm-stock-max="NaN"
                        step="1"
                        mm-step="1"
                        fdprocessedid="f05xsu"
                        onChange={(e) => {
                          enterProductCount(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        class="js-qty__adjust js-qty__adjust--minus"
                        aria-label="Reduce item quantity by one"
                        fdprocessedid="nqqfb9"
                        onClick={disbt}
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          class="icon icon-minus"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="#444"
                            d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                          ></path>
                        </svg>
                        <span class="icon__fallback-text" aria-hidden="true">
                          −
                        </span>
                      </button>

                      <button
                        type="button"
                        class="js-qty__adjust js-qty__adjust--plus"
                        aria-label="Increase item quantity by one"
                        fdprocessedid="mkiqbe"
                        onClick={inbt}
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          class="icon icon-plus"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="#444"
                            d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                          ></path>
                        </svg>
                        <span class="icon__fallback-text" aria-hidden="true">
                          +
                        </span>
                      </button>
                    </div>
                    <h6>See All Features</h6>

                    <button className="btn btn-dark m-3">
                      <a
                        className="NavLink text-light"
                        data-bs-dismiss={ctQ > 9 ? "modal" : ""}
                        onClick={(e) => {
                          addtoItme(ModalData?.product_id);
                        }}
                      >
                        ADD TO CART
                      </a>
                    </button>
                    <button className="btn btn-dark m-3">
                      <a
                        className="NavLink text-light"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          navigate("/product-details/" + ModalData?.product_id);
                        }}
                      >
                        VIEW DETAILS
                      </a>
                    </button>

                    <div className="mt-2 mb-2 ModalTextBox p-1">
                      <div
                        className="modal_description mb-15"
                        dangerouslySetInnerHTML={{
                          __html:
                            ModalData?.product_description?.length > 150
                              ? `${ModalData?.product_description.substring(
                                0,
                                150
                              )}....`
                              : ModalData?.product_description,
                        }}
                      ></div>
                    </div>

                    <div className="SocialMedia">
                      <h6>Share Our product</h6>
                      <div className=" SocialMediaIcons d-flex ms-3">
                        <div className="col-1">
                          <a
                            href="https://www.facebook.com/kaafi.pyaar?mibextid=ZbWKwL"
                            target={"_blank"}
                          >
                            <FacebookIcon className=""></FacebookIcon>
                          </a>
                        </div>
                        <div className="col-1">
                          <a
                            href="https://www.instagram.com/kafipyar/?igshid=YmMyMTA2M2Y%3D"
                            target={"_blank"}
                          >
                            <InstagramIcon className=""></InstagramIcon>
                          </a>
                        </div>
                        <div className="col-1">
                          <a
                            href="https://in.pinterest.com/kafipyar/?invite_code=bfb4217e282747ec81cd88b23ea9c356&sender=1041809463708864739"
                            target={"_blank"}
                          >
                            <PinterestIcon className=""></PinterestIcon>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Box Ends */}
      {/* Carousel Buying Card Start */}

      <div className="container-fluid text-center mt-5">
        {/* <div className="TitleText ">
          <h3 className="p-3">Motifs of the Month</h3>
        </div> */}
        <div className="sliderCategory">
          {/* <div className='container'> */}
          <div className="d-flex  " style={{ flexWrap: "wrap", gap: "5px" }}>
            {get_category &&
              get_category?.map((data, i) => {
                return (
                  <div
                    onClick={(e) => {
                      if (active !== data?.id) {
                        setCategory(null)
                        
                      }
                      setSubActive(null)
                      setAction(!action)
                      navigate("/shop", { state: { id: data?.id } });
                    }}
                    className={`col sliderCategorybox  ${active === data?.id && "sliderCategorybox-active"}`}
                    key={i}
                    style={{ position: "relative" }}
                  >
                    {data?.title}


                  </div>
                );
              })}
          </div>
        </div>

        {
          category &&
          <div style={{ color: "white", backgroundColor: "#e07bb3", width: "100%", padding: "10px", display: "flex", flexWrap: "wrap", gap: "20px" }}>
            {
              category?.map((data, i) => {
                return <div key={i} className={`sliderCategorybox ${subactive === data?.id && "sliderCategorybox-active"}`} onClick={(e) => {
                  
                  !subactive || subactive !== data?.id ? cateproduct({ sub: data?.id }) : setAction(!action);
                  !subactive || subactive !== data?.id ? setSubActive(data?.id) : setSubActive(null);
                }}>
                  {data?.title}
                </div>
              })
            }
          </div>
        }


        <div className="row mt-4 row-cols-2  row-cols-md-4">
          {filtered &&
            filtered?.map((data, i) => {
              {
                /* console.log(data); */
              }

              return (
                <div
                  className="col contained"
                  style={{ position: "relative" }}
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal"
                  // onClick={(e) => {
                  //   ModalBox(data);
                  // }}
                  key={i}
                >
                  <div className="" style={{ position: "absolute" }}>
                    {/* <h6 className="BlackTag ">Sale</h6> */}
                  </div>
                  <img
                    onClick={(e) => {
                      navigate("/product-details/" + data?.product_id);
                    }}
                    src={data?.product_image}
                    alt="BuyingCardImage"
                    className="BuyingImage"

                  />
                  <div className="viewIcon">
                    <a
                      href="#"
                      // target={"_blank"}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={(e) => {
                        ModalBox(data);
                      }}
                    >
                      <i class="fa fa-eye"></i>
                      {/* <InstagramIcon className=""></InstagramIcon> */}
                    </a>
                  </div>
                  <div
                    className="CardText "
                    onClick={(e) => {
                      navigate("/product-details/" + data?.product_id);
                    }}
                  >
                    {/* <button className="btn btn-dark m-3">
                      <a
                        className="NavLink text-light"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          navigate("/product-details/" + ModalData?.product_id);
                        }}
                      >
                        VIEW DETAILS
                      </a>
                    </button> */}
                    <h5 className=" pt-3 " style={{ cursor: "pointer", color: "black" }}>{data?.product_name}</h5>

                    <p>
                      Rs. {data?.price} &#8377;
                    </p>
                  </div>
                </div>
              );
            })}

          {/* <div
            className="col contained"
            style={{ position: "relative" }}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <div className="" style={{ position: "absolute" }}>
              <h6 className="BlackTag ">Sale</h6>
            </div>
            <img
              src="https://cdn.shopify.com/s/files/1/0466/5763/4466/products/Chand-ka-tukda_Sweets-box_45-degree_1_360x.jpg?v=1602141467"
              alt="BuyingCardImage"
              className="BuyingImage"
            />
            <div className="CardText ">
              <h5 className=" pt-3">Chand ka tukda Sweet Box</h5>
              <p>
                <span
                  style={{ textDecoration: "line-through", color: "black" }}
                >
                  Rs.1,190
                </span>{" "}
                From Rs. 590 <span className="text-danger">Save 600</span>
              </p>
            </div>
          </div>
          <div
            className="col contained"
            style={{ position: "relative" }}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <div className="" style={{ position: "absolute" }}>
              <h6 className="BlackTag ">Sale</h6>
            </div>
            <img
              src="https://cdn.shopify.com/s/files/1/0466/5763/4466/products/Ladies-day-out_Sweets-box_45-degree_1_copy_360x.jpg?v=1602179992"
              alt="BuyingCardImage"
              className="BuyingImage"
            />
            <div className="CardText ">
              <h5 className=" pt-3">Ladies Day Out Sweet Box</h5>
              <p>
                <span
                  style={{ textDecoration: "line-through", color: "black" }}
                >
                  Rs.1,190
                </span>{" "}
                From Rs. 590 <span className="text-danger">Save 600</span>
              </p>
            </div>
          </div>
          <div
            className="col contained"
            style={{ position: "relative" }}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <div className="" style={{ position: "absolute" }}>
              <h6 className="BlackTag ">Sale</h6>
            </div>
            <img
              src="https://cdn.shopify.com/s/files/1/0466/5763/4466/products/Ek-Mehfil_Sweets-box_45-degree_360x.jpg?v=1602103524"
              alt="BuyingCardImage"
              className="BuyingImage"
            />
            <div className="CardText ">
              <h5 className=" pt-3">Ek Mehafil Sweet Box</h5>
              <p>
                <span
                  style={{ textDecoration: "line-through", color: "black" }}
                >
                  Rs.1,190
                </span>{" "}
                From Rs. 590 <span className="text-danger">Save 600</span>
              </p>
            </div>
          </div> */}
        </div>
        {

          <div className="shop_toolbar t_bottom">
            <a className="socialHBtn" onClick={hendlePagination}>
              {loader ? "Loading....." : 'Load More'}
            </a>
          </div>


        }

      </div>
      {/* Carousel Buying Card End */}
    </>
  );
};

export default ShoppingCards;
