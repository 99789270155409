import {
  LOADING,
  HOME_DATA,
  SIGNUP_WITH_NUMBER,
  OTP_VERIFY,
  VIEW_CART,
  INCRESS_QTY,
  DECRESS_QTY,
  DELETE_FROM_CART,
  ADD_CART,
  CATEGORY_PRODUCT,
  GET_CATEGORY,
  PRODUCT_DETAILS,
  SEARCH_PRODUCT,
  GET_CITY,
  GET_ADDRESS,
  BOOK_ORDER,
  GET_ORDER_BY_ID,
  GET_ORDER,
  FATCH_PRO,
  CART_ITME_ADD,
  CHECK_PINCODE,
  SHIPPING_CHARGE,
  CHECK_DISCOUNT_CODE,
  STATIC_PAGES,
  CANCLE_ORDER,
  GET_QUERY_BY_STATUS,
} from "../common/constant";

const initialState = {
  loading: false,
  home_data: {},
  add_cart: {},
  view_cart: {},
  incress_qty: {},
  decress_qty: {},
  delete_cart_itme: {},
  category_product: {},
  get_category: [],
  product_details: {},
  search_product: [],
  get_city: [],
  get_address: [],
  book_order: {},
  get_order_by_id: {},
  get_order: [],
  facth_pro: [],
  get_guery_by_status: [],
  add_cart_itme: "",
  check_pincode: {},
  shipping_charge: {},
  check_discount_code: {},
  static_pages: {},
  cancle_order: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case HOME_DATA:
      return { ...state, home_data: action.payload };
    case OTP_VERIFY:
      return { ...state, otp_verify: action.payload };
    case VIEW_CART:
      return { ...state, view_cart: action.payload };
    case INCRESS_QTY:
      return { ...state, incress_qty: action.payload };
    case DECRESS_QTY:
      return { ...state, decress_qty: action.payload };
    case DELETE_FROM_CART:
      return { ...state, delete_cart_itme: action.payload };
    case ADD_CART:
      return { ...state, add_cart: action.payload };
    case CATEGORY_PRODUCT:
      return { ...state, category_product: action.payload };
    case GET_CATEGORY:
      return { ...state, get_category: action.payload };
    case PRODUCT_DETAILS:
      return { ...state, product_details: action.payload };
    case SEARCH_PRODUCT:
      return { ...state, search_product: action.payload };
    case GET_CITY:
      return { ...state, get_city: action.payload };
    case GET_ADDRESS:
      return { ...state, get_address: action.payload };
    case BOOK_ORDER:
      return { ...state, book_order: action.payload };
    case GET_ORDER_BY_ID:
      return { ...state, get_order_by_id: action.payload };
    case GET_ORDER:
      return { ...state, get_order: action.payload };
    case FATCH_PRO:
      return { ...state, facth_pro: action.payload };
    case CART_ITME_ADD:
      return { ...state, add_cart_itme: action.payload };
    case CHECK_PINCODE:
      return { ...state, check_pincode: action.payload };
    case SHIPPING_CHARGE:
      return { ...state, shipping_charge: action.payload };
    case CHECK_DISCOUNT_CODE:
      return { ...state, check_discount_code: action.payload };
    case STATIC_PAGES:
      return { ...state, static_pages: action.payload };
    case CANCLE_ORDER:
      return { ...state, cancle_order: action.payload };
    case GET_QUERY_BY_STATUS:
      return { ...state, get_guery_by_status: action.payload };

    default:
      return state;
  }
};
