export const URL = {
  // dev

  // API_BASE_URL: "http://192.168.0.65:8000",
  // API_BASE_URL: "http://jwellerydemo.softwarejaipur.com/rest",
  API_BASE_URL: "https://admin.kafipyar.com/rest",
  // API_BASE_URL: "http://localhost:8000",

  // user

  register: "/v2/register",
  send_otp: "/v2/register/send_otp",
  otpVerify: "/v2/register/verify_otp",
  login: "/v2/register/login",

  home: "/v3/home",
  static_pages: "/v3/home/static_pages",

  add_cart: "/v2/cart/add_cart",
  view_cart: "/v2/cart/view_cart",
  incress_qty: "/v2/cart/incress_qty",
  decress_qty: "/v2/cart/decress_qty",
  delete_from_cart: "/v2/cart/delete_from_cart",
  category_product: "/v3/home/get_sub_category",
  filter_category_product: "/v3/home/get_sub_sub_category",
  get_category: "/v3/home/get_category",
  product_details: "/v3/product/product_details",
  search_product: "/v3/product/search_product",
  add_address: "/v2/cart/add_address",
  get_address: "/v2/cart/get_address",
  get_city: "/v2/product/get_city",
  fatch_pro: "/v3/product/fatch_pro",

  delete_address: "/v2/cart/delete_address",

  book_order: "/v2/order/book_order",
  update_order: "/v2/order/update_order",
  get_order_by_id: "/v2/order/get_order_by_id",
  get_order: "/v2/order/get_order",
  cancle_order: "/v2/order/cancle_order",
  check_pincode: "/v2/user/check_pincode",
  shipping_charges: "/v2/user/get_admin_details",
  check_discount_code: "/v2/Order/check_discount_code",
  // cancle_order: "/v2/order/cancle_order",
  get_query_by_status: "/v2/Query/get_query_by_status",
};
