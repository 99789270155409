import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "../common/api";

import {
  LOADING,
  REGISTER,
  SEND_OTP,
  OTP_VERIFY,
  LOGIN,
} from "../common/constant";
import Token from "../../Utils/Auth/Token";

const loading = (data) => {
  return { type: LOADING, payload: data };
};
const registerAction = (data) => {
  return { type: REGISTER, payload: data };
};
const sendOtpAction = (data) => {
  return { type: SEND_OTP, payload: data };
};
const otpverifyAction = (data) => {
  return { type: OTP_VERIFY, payload: data };
};
const loginAction = (data) => {
  return { type: LOGIN, payload: data };
};

export const signupwithemail = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.register}`, data);
      if (response?.status == 200) {
        dispatch(registerAction(response?.data?.user));

        toast.success(response?.data?.message);

        setTimeout(navigate("/"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const send_otp = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.send_otp}`, data);
      if (response?.status == 200) {
        dispatch(sendOtpAction(response?.data?.user));

        toast.success(response?.data?.message);

        setTimeout(navigate("/"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const login = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.login}`, data);

      if (response?.data?.success == true) {
        dispatch(loginAction(response));



        sessionStorage.setItem("item_key", JSON.stringify( response?.data?.data));
        toast.success(response?.data?.message);
        Token.setToken(response?.data?.data?.token);
        Token.setUserName(response?.data?.data?.full_name);
        Token.setUserEmail(response?.data?.data?.email);

        // setUserName
        navigate(-1);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const otpverify = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerify}`, {
        user_mobile: data?.user_mobile,
        otp: data?.otp,
      });

      if (response?.status == 200) {
        if (response?.data?.success == true) {
          // dispatch(otpverifyAction(response?.data?.user));
          const response = await api.post(`${URL.register}`, data);

          // console.log(response);
          if (response?.status == 200) {
            dispatch(registerAction(response?.data?.data));

            toast.success(response?.data?.message);
            Token.setToken(response?.data?.data?.token);
            Token.getUserName(response?.data?.data?.full_name);
            Token.getUserEmail(response?.data?.data?.email);
            setTimeout(navigate("/"), 1000);
            dispatch(loading(false));
          } else {
            toast.error(response?.data?.message);
          }
        } else {
          toast.error(response?.data?.message);
          // Token.setToken(response?.data?.token);
          // Token.setToken(response?.data?.token)
          // setTimeout(navigate("/"), 1000);
          dispatch(loading(false));
        }
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
